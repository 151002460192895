import styled from "styled-components";
import React, {useState} from "react";
import {
  useAddMoyenFormationPlanning,
  useGetFormationPlanningMember, useGetFormationPlanningMemberWithMoyenFccof,
  useGetRight,
} from "@mbj-front-monorepo/data-access";
import {
  createSelectColumn,
  MbjTableV2,
  MbjLoading,
  MbjButton, MbjScrollArea, useModal, MbjIconButton, MbjConfirmModal,
} from "@mbj-front-monorepo/ui";

import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { BiCalendar, BiLineChartDown, BiTrash } from "react-icons/bi";

interface PlanFormationByFccofCtrlProps{
  className?:string;
}

const PlanFormationByFccofCtrl = (props:PlanFormationByFccofCtrlProps) => {
  const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Collaborateurs", "write", "Mes collaborateurs")
  const formationPlannings = useGetFormationPlanningMemberWithMoyenFccof()
  const { isShowing: isShowingFp, toggle: toggleFp } = useModal();
  const {isShowing: isShowingDetail, toggle: toggleDetail} = useModal();
  const {isShowing: isShowingCalendar, toggle: toggleCalendar} = useModal()
  const columnHelper = createColumnHelper<GetElementType<typeof formationPlannings.data>>();
  const [fpInfo, setFpInfo] = useState({fpId: 0, idfccof: 0})
  const mutation = useAddMoyenFormationPlanning(fpInfo.fpId)
  const formatDate = (d: string) => {
    const date = new Date(d);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const handleSend = (row: any) => {
    setFpInfo({fpId: row.id, idfccof: row.fccofID})
    toggleFp()
  }

  const columns = [
    columnHelper.group({
      header: 'Collaborateurs',
      columns: [
        columnHelper.accessor((row: any) => row.nom, {
          header: "Nom",
        }),
        columnHelper.accessor((row: any) => row.prenom, {
          header: "Prénom",
        }),
      ]
    }),
    columnHelper.accessor((row: any) => row.activite, {
      header: "Compétence",
    }),
    columnHelper.accessor((row: any) =>  !row.souhait_at ? 'Non planifiée' : formatDate( row.souhait_at) , {
      header: "Date de formation",
    }),
    columnHelper.display({
      header: 'Actions',
      id: 'actions',
      cell: (props: any) => <div className="btn-actions">
        <MbjIconButton
          size={"sm"}
          onClick={() => handleSend(props.row?.original)}
          ariaLabel={"Stats"}
          icon={<BiTrash />}
          themeColor={"warning"}
          isRound={false}
        />
      </div>,
    }),
  ];
  const table = useReactTable({
    data: formationPlannings?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    enableRowSelection: true,
  })


  return (
    <div className={`Priorisation_listing pageStd ${props.className}`}>
      {formationPlannings.isLoading ? (
        <MbjLoading />
      ) : formationPlannings.isError ? (
        <p>Error: {formationPlannings.error.message}</p>
      ) : (
        <>
          <div className={"space-between"}>
            <div className={"ResultNumber"}>{table.getRowModel().rows.length > 1 ?  `${table.getRowModel().rows.length} résultats` : `${table.getRowModel().rows.length} résultat`}</div>
            <div style={{display:"flex", alignItems:"center", gap:"10px"}}>
              {((table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && isAuthWrite) && (
                <>
                  <MbjButton themeColor={"warning"} isPending={false}>
                    Retirer {table.getSelectedRowModel().rows.length} collaborateur(s)
                  </MbjButton>
                </>
              )}
            </div>
          </div>
          <MbjScrollArea overflowX={"auto"}>
            <MbjTableV2 table={table} Multiple={true}/>
          </MbjScrollArea>
        </>
      )}
      <MbjConfirmModal
        isShowing={isShowingFp}
        hide={toggleFp}
        title="Confirmation de la suppression de ce moyen de formation pour ce plan de formation"
        confirmText={`Etes-vous sûr de vouloir retirer ${table.getSelectedRowModel().rows.length} collaborateur(s) ?`}
        confirmFunction={() => {
          const data: any = {type: 'fccof', action: false, id: fpInfo.idfccof }
          mutation.mutate(data, {
            onSuccess: () => {
              toggleFp()
            }
          })
        }}
        isLoading={mutation.isLoading}
      />
    </div>

  )
}
export const PlanFormationByFccof = styled(PlanFormationByFccofCtrl)`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0 50px;

    .space-between {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ResultNumber {
      font-size: 20px;
    }

    .btn-actions {
      display: flex;
      justify-content: space-between;
    }

    .btn-actions button:first-child {
      margin-right: 10px;
    }

    .blk-plan {
      margin: 10px 0;
    }

    .plprg {
      margin-bottom: 0.5rem;
    }

    .plprg_select {
      display: block;
      margin: 20px 10px 20px 0;
    }
  `
;
