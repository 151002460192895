import styled from "styled-components";
import {GetElementType} from "@mbj-front-monorepo/utils";
import {Operation, useDeleteService, useGetMemberMe, useMutateService} from "@mbj-front-monorepo/data-access";
import {MbjButton, MbjCardBack, MbjLinkAction, MbjSimpleInput, MbjSimpleInputContainer} from "@mbj-front-monorepo/ui";
import {useForm} from "react-hook-form";
import * as React from "react";
import {useEffect, useMemo} from "react";

interface CardModifierEquipeCtrlProps{
    className?:string;
    Service: GetElementType<Operation<"getServicesCollection">>;
    readonly:boolean;
}

const CardModifierEquipeCtrl = (props:CardModifierEquipeCtrlProps)=>{
    const memberQuery = useGetMemberMe();

    const mutationService = useMutateService(props.Service.id || -1, memberQuery.data?.id);
    const deleteService = useDeleteService(memberQuery.data?.id || -1);

    const {
        handleSubmit,
        register,
        formState: { errors }, reset
    } = useForm<Operation<"putServicesItem", "requestBody">>({
        mode: "onChange",
        defaultValues: useMemo(()=>{
            return props.Service
        }, [props.Service]),
    });
    useEffect(() => {
        reset(props.Service)
    }, [props.Service]);
    const onSubmit = (data: Operation<"putServicesItem", "requestBody">) => {
        mutationService.mutate(data);
    };
    const TrashMe = ()=>{
        deleteService.mutate(props.Service.id || -1)
    }
    return (
        <MbjCardBack title={`${!props.readonly ? "Modifier équipe" : "Equipe"} ${props.Service.libelle}`}>
            <div className={`modif_equipe ${props.className}`}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <MbjSimpleInputContainer errors={errors} label={"Libellé"}>
                        <MbjSimpleInput
                            name={"libelle"}
                            id={"libelle"}
                            disabled={props.readonly}
                            noPadding
                            register={register}
                            registerOptions={{
                                required: {
                                    value: true,
                                    message: "Libellé requis",
                                },
                                minLength: {
                                    value: 4,
                                    message: "Nécessite au moins 4 caractères",
                                },
                            }}
                        />
                    </MbjSimpleInputContainer>
                    <div className={`wrap_sender`}>
                        {!props.readonly &&
                            <MbjButton isPending={mutationService.isLoading}>Enregistrer</MbjButton>
                        }
                    </div>
                </form>
                {props.Service.Employees?.length === 0 && !props.readonly &&
                    <MbjLinkAction action={TrashMe} themeColor={"warning"} libelle={"supprimer l'équipe"}/>
                }
            </div>
        </MbjCardBack>
    )
}

export const CardModifierEquipe = styled(CardModifierEquipeCtrl)`
  .wrap_sender{
    padding: 0.5rem 0;
    display: flex;
    justify-content: flex-end;
  }
`
