import { Navigate, Route, Routes } from "react-router-dom";
import EntrepriseDashboard from "./Dashboard/EntrepriseDashboard";
import React from "react";
import EntrepriseEquipes from "./Equipes/EntrepriseEquipes";
import Neo4j from "./Neo4j/Neo4j";
import EntrepriseServices from "./Services/EntrepriseServices";

export function EntrepriseRooter(){
    return (
        <Routes>
            <Route path="" element={<Navigate to="dashboard" />} />
            <Route path={"/dashboard"} element={<EntrepriseDashboard/>}/>
            <Route path={"/services"} element={<EntrepriseServices />}/>
            <Route path={"/equipes"} element={<EntrepriseEquipes />}/>
            <Route path={"/neo4j/:key"} element={<Neo4j/>}/>
        </Routes>
    )
}
