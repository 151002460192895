import styled from "styled-components";
import {useGetMetrixCompForUM} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {useMemo} from "react";

interface MatrixCompMetierCtrlProps {
  className?:string;
}

interface OneActiThis{
  id:number;
  libelle:string;
}
const MatrixCompMetierCtrl = (props: MatrixCompMetierCtrlProps) => {
  const CompQuery = useGetMetrixCompForUM();
  const TabClass = ['--null', '--very-bad', '--bad', '--almost-good', '--good', '--good']
  const listActi = useMemo(()=>{
    const myList:OneActiThis[] = [];
    if(CompQuery.data){
      const myActs = CompQuery.data[0].ActiviteScorings?.map(as=>as.activite);
      console.log(myActs ? myActs.length : '!!')
      console.log(CompQuery.data.length)
      if(myActs){
        myActs.forEach(item=> {
          const acti: OneActiThis = {
            id: item?.id||0,
            libelle: item?.libelle||'',
          };
          myList.push(acti);
        })
      }

    }
    return myList;
  }, [CompQuery.data])
  return (
    <div className={`matrix_user_metier ${props.className}`}>
      {CompQuery.isLoading ? <MbjLoading/> :
        <div className="wrap_tabx">
          <table>
            <thead>
            <tr>
              <th></th>
              {listActi.map((item, idx) => (
                <th key={`H${item.id}${idx}`}>{item.libelle}</th>
              ))}
            </tr>
            </thead>
            <tbody>
            {CompQuery.data?.map((item, idx) => (
              <tr key={`LMetrix${item.id}${idx}`}>
                <td>{item.userMetier?.namePerso || ''}</td>
                {item.ActiviteScorings?.map((actiS, idx) => (
                  <td key={`Cell${item.id}${actiS.id}${idx}`}>
                    <div className={`myCell ${TabClass[(actiS.niveau||0)]}`}>{actiS.niveau}</div>
                  </td>
                ))}
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      }
    </div>
  )
}

export const MatrixCompMetier = styled(MatrixCompMetierCtrl)`
  width:100%;
  padding-top: 20px;

  .myCell{
    margin: auto;
    padding: 0.25rem 1.5rem;
    background: #ccc;
    width: 100%;
    text-align: center;
    border-radius: 4px;
    &.--null{
      background: #e2dcdc;
      color:${props=>props.theme.grey};
    }
    &.--very-bad{
      background: ${props=>props.theme.warningPastel};
      color:${props=>props.theme.warningDarken};
    }
    &.--bad {
      background: ${props=>props.theme.complementaryPastel};
      color:${props=>props.theme.complementaryDarken};
    }
    &.--almost-good{
      background: ${props=>props.theme.secondaryPastel};
      color:${props=>props.theme.secondaryDarken};
    }
  &.--good{
    background: ${props=>props.theme.thirdLighten};
    color:${props=>props.theme.thirdDarken};
  }
  }
  .wrap_tabx{
    margin: auto;
    width:78vw;
    max-height: 600px;
    background: white;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
  }
  table{
    border-spacing : 0;
    border-collapse : collapse;
    thead{
      tr{

        th{
          background: white;
          position: sticky;
          top:0;
          text-align: center;
          font-size: 13px;
          min-width: 110px;
          padding: 0.25rem 0.5rem;
          border-bottom: solid ${props=>props.theme.primary} 1px;
          &:first-child{
            min-width: 280px;
          }
        }
      }
    }
    tbody{
      tr{
        td{
          &:first-child{
            font-weight: bold;
          }
          padding: 0.25rem;
        }
      }
    }
  }
  .cell_compare{
    margin: auto;
    padding: 0.25rem 1.5rem;
    background: #ccc;
    width: 100%;
    text-align: center;
    border-radius: 4px;
    &.good{
      background: ${props=>props.theme.thirdLighten};
    }
    &.same{
      background: ${props=>props.theme.primaryPastel};
    }
    &.bad{
      background: ${props=>props.theme.warningPastel};
    }
  }
`
