import {GetListAuthOnglet, logout, useGetConnected, useGetMemberMe} from "@mbj-front-monorepo/data-access";
import {DesktopLayout, MbjBlockMenu, MbjLoading} from "@mbj-front-monorepo/ui";
import {BsBank2, BsChatSquareText} from "react-icons/bs";
import React, {useEffect, useState} from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {app_version, queryClient} from "./App";
import {FaChartLine, FaLink, FaSuitcase, FaUserCircle, FaUsers} from "react-icons/fa";
import EntrepriseDashboard from "../pages/Entreprise/Dashboard/EntrepriseDashboard";
import {EntrepriseRooter} from "../pages/Entreprise/EntrepriseRooter";
import {MetiersRooter} from "../pages/Metiers/MetiersRooter";
import {DynamiqueEffectifRooter} from "../pages/DynamiqueEffectif/DynamiqueEffectifRooter";
import {CollaborateursRooter} from "../pages/Collaborateurs/CollaborateursRooter";
import {AlliancesRooter} from "../pages/Alliances/AlliancesRooter";
import {Messagerie} from "../pages/Messagerie/Messagerie";
import {NotFound} from "../components/NotFound/NotFound";
import {LegalMentions} from "../pages/LegalMentions/LegalMentions";


export function Entreprise(){
  const memberQuery = useGetMemberMe();
  const UserConnectedQuery = useGetConnected();
  const subMetier = process.env["REACT_APP_DEFAULT_THEME"] !== 'sqyTheme' ?
    [
      {libelle:"Tableau de bord",nivo:GetListAuthOnglet("Métiers", "Tableau de bord"), to:'metiers/dashboard', path:'metiers_dashboard'},
      {libelle:"Métiers de l'entreprise",nivo:GetListAuthOnglet("Métiers", "MetierEntreprise"), to:'metiers/listing', path:'metiers_listing'},
      {libelle:"Matrice Métiers",nivo:GetListAuthOnglet("Métiers", "MetierEntreprise"), to:'metiers/matrix_metiers', path:'metiers_matrix_metiers'},
      {libelle:"Recherche compétence",nivo:GetListAuthOnglet("Métiers","Recherche Compétence"), to:'metiers/search_competence', path:'search_competence'},
    ]:
    [
      {libelle:"Tableau de bord",nivo:GetListAuthOnglet("Métiers", "Tableau de bord"), to:'metiers/dashboard', path:'metiers_dashboard'},
      {libelle:"Métiers de l'entreprise",nivo:GetListAuthOnglet("Métiers", "MetierEntreprise"), to:'metiers/listing', path:'metiers_listing'}
    ]
  const [mySubCollab, setMySubCollab] = useState([
    {libelle:"Tableau de bord",nivo:GetListAuthOnglet("Collaborateurs","Tableau de bord"), to:'collaborateurs/dashboard', path:'collaborateurs_dashboard'},
    {libelle:"Mes collaborateurs",nivo:GetListAuthOnglet("Collaborateurs","Mes collaborateurs"), to:'collaborateurs/listingc', path:'collaborateurs_listingc'},
    {libelle:"Besoins de formation",nivo:GetListAuthOnglet("Collaborateurs","Besoins formation"), to:'collaborateurs/formation', path:'collaborateurs_formation'},
    {libelle:"Pilotage RH",nivo:GetListAuthOnglet("Collaborateurs","Pilotage RH"), to:'collaborateurs/pilotagerh', path:'collaborateurs_pilotagerh'},
    {libelle:"Validation niveau",nivo:GetListAuthOnglet("Collaborateurs","Nouvelle formation"), to:'collaborateurs/new_formation', path:'new_formation'},
    {libelle:"Equipe manager",nivo:GetListAuthOnglet("Collaborateurs","Equipe Manager"), to:'collaborateurs/equipe_manager', path:'equipe_manager'},
    {libelle:"Recherche compétence",nivo:GetListAuthOnglet("Collaborateurs","Recherche Compétence"), to:'collaborateurs/search_competence', path:'search_competence'},
  ])
  const BlockMenus:MbjBlockMenu[] =[
    {libelle:"Entreprise",nivo:GetListAuthOnglet("Entreprise"), subMenus:[
        {libelle:"Tableau de bord",nivo:GetListAuthOnglet("Entreprise", "Tableau de bord"), to:'entreprise/dashboard', path:'entreprise_dashboard'},
        {libelle:"Equipes",nivo:GetListAuthOnglet("Entreprise", "Equipes"), to:'entreprise/equipes', path:'entreprise_equipes'},
        {libelle:"Services",nivo:GetListAuthOnglet("Entreprise", "Services"), to:'entreprise/services', path:'entreprise_services'},
        ],
      icon:<BsBank2 title="Entreprise icône" />},
    {libelle:"Métiers",nivo:GetListAuthOnglet("Métiers"), subMenus:subMetier, icon:<FaSuitcase title="Metiers icône" />},
    {libelle:"Collaborateurs",nivo:GetListAuthOnglet("Collaborateurs"), subMenus:mySubCollab, icon:<FaUsers title="collabs icône" />},
    {libelle:"Dynamique de l'effectif",nivo:GetListAuthOnglet("Dynamique de l'effectif"), subMenus:[
        {libelle:"Tableau de bord", nivo:GetListAuthOnglet("Dynamique de l'effectif","Tableau de bord"), to:'dynamique/dashboard', path:'dynamique_dashboard'},
        {libelle:"Recrutements", nivo:GetListAuthOnglet("Dynamique de l'effectif","Recrutements"), to:'dynamique/recrutements', path:'dynamique_recrutements'},
        {libelle:"Reclassements", nivo:GetListAuthOnglet("Dynamique de l'effectif","Reclassements"), to:'dynamique/reclassements', path:'dynamique_reclassements'},
        {libelle:"Chiffres clés", nivo:GetListAuthOnglet("Dynamique de l'effectif","Chiffres clés"), to:'dynamique/chiffrecles', path:'dynamique_chiffrecles'},
        ], icon:<FaChartLine title="dyn icône" />},
    {libelle:"Alliances",nivo:GetListAuthOnglet("Alliances"), subMenus:[
        {libelle:"Tableau de bord", nivo:GetListAuthOnglet("Alliances","Tableau de bord"), to:'alliances/dashboard', path:'alliances_dashboard'},
        {libelle:"Mes Alliances", nivo:GetListAuthOnglet("Alliances","Mes Alliances"), to:'alliances/mesalliances', path:'alliances_mesalliances'}
    ], icon:<FaLink title="alliances icône" />},
    {libelle:"Messagerie",nivo:GetListAuthOnglet("Messagerie"), to:"messagerie", icon:<BsChatSquareText title="messagerie icône" />},
  ]
  return (
    <div className={`entreprise`}>
      <Routes>
        <Route element={
          <DesktopLayout
              numVersion={app_version}
              Blocks={BlockMenus}
              DisconnectCallBack={()=>logout(queryClient)}
              userLogo={
                memberQuery.isLoading ? (
                    <MbjLoading height={"50%"} />
                ) : memberQuery.isError ? (
                    <p>Error !</p>
                ) : memberQuery.data?.logo !== null ? (
                    <img src={`${process.env["REACT_APP_API_URL_ROOT"]}/logos/members/${memberQuery.data.logo}`} />
                ) : (
                    <FaUserCircle className={"icon-profile-menu"} />
                )
              }
          />
        }>
          <Route path="" element={<Navigate to="entreprise/dashboard" />} />
          <Route path={"entreprise/dashboard"} element={<EntrepriseDashboard/>}/>
          <Route path={"entreprise/*"} element={<EntrepriseRooter/>}/>
          <Route path={"metiers/*"} element={<MetiersRooter/>}/>
          <Route path={"dynamique/*"} element={<DynamiqueEffectifRooter/>}/>
          <Route path={"collaborateurs/*"} element={<CollaborateursRooter/>}/>
          <Route path={"alliances/*"} element={<AlliancesRooter/>}/>
          <Route path={"messagerie"} element={<Messagerie/>}/>
            <Route path="/mentions" element={<LegalMentions />} />
            <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  )
}
