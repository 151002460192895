import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useGetMemberMe } from "../member";
import { fetchApi, Operation } from "../../services/fetchApi";

export const useGetEmployeeMe = () => {
  const promise = () => fetchApi("/api/employees/me", "get");
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employee", "me"], promise);
};

export const useGetEmployees = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/employees", { method: "get", query: { MemberMaster: memberId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employees", { memberId }], promise, {
    enabled: !!memberId,
  });
};

export const useGetEmployeesByMember = (memberId: number | undefined) => {
  const promise = () => fetchApi("/api/employees", { method: "get", query: { MemberMaster: memberId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employees", { memberId }], promise, {
    enabled: !!memberId,
  });
};

export const useGetEmployee = (employeeId?: number) => {
  const promise = () => fetchApi("/api/employees/{id}", { method: "get", params: { id: employeeId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employees", { employeeId }], promise, {
    enabled: !!employeeId,
  });
};

export const useAddEmployee = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  return useMutation(
    (data: Operation<"postEmployeeCollection", "requestBody">) => {
      console.log(data);
      return fetchApi("/api/employees", {
        method: "post",
        json: { ...data, MemberMaster: "/api/members/" + memberQuery.data?.id},
      });
    },
    {
      onSuccess: (newEmployee) => {
        queryClient.invalidateQueries(["employees", { memberId: memberQuery.data?.id }]);
        queryClient.setQueryData(["employees", { employeeId: newEmployee.id }], newEmployee);
        toast.success("Collaborateur ajouté !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
export const useConvertEmployeeToUser = (id:number)=>{
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  return useMutation(
    () => {

      return fetchApi("/api/employees/{id}/convert_to_user", {
        method: "get",
        params:{id:id+""}
      });
    },
    {
      onSuccess: (newEmployee) => {
        queryClient.invalidateQueries(["employees", id]);
        queryClient.invalidateQueries(["user_single"]);
        queryClient.setQueryData(["employees", { employeeId: newEmployee.id }], newEmployee);
        toast.success("Compte converti !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}
export const useConvertEmployeeToUser2 = ()=>{
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  return useMutation(
    (id:number) => {
      return fetchApi("/api/employees/{id}/convert_to_user", {
        method: "get",
        params:{id:id+""}
      });
    },
    {
      onSuccess: (newEmployee) => {
        queryClient.invalidateQueries(["employees", newEmployee.id]);
        queryClient.invalidateQueries(["user_single"]);
        queryClient.setQueryData(["employees", { employeeId: newEmployee.id }], newEmployee);
        toast.success("Compte converti !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}

export const useMutateEmployee = (employeeId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"putEmployeeItem", "requestBody">) =>
      fetchApi("/api/employees/{id}", { method: "put", params: { id: employeeId + "" }, json: data }),
    {
      onSuccess: (newEmployee) => {
        queryClient.invalidateQueries(["employees"]);
        queryClient.setQueryData(["employees", { employeeId }], newEmployee);
        toast.success("Collaborateur modifié !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteEmployee = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (employeeId: number | number[]) => {
      if (Array.isArray(employeeId)) {
        return Promise.all(
          employeeId.map((id) => fetchApi("/api/employees/{id}", { method: "delete", params: { id: id + "" } }))
        );
      }
      return fetchApi("/api/recrutement_saveds/{id}", { method: "delete", params: { id: employeeId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["employees"]);
        toast.success("Employé supprimé", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useUnToAnalyze = () => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      mutationFn: (items: number[]) => {
        return fetch(`${process.env["REACT_APP_API_URL"]}/api/employee_needs_form_unanalyze`, {
          method: 'DELETE',
          body: JSON.stringify({data: items}),
          headers: {"Content-Type": "application/json", "Accept": "application/json"},
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["employees_needs_form_toanalyze"]);
        toast.success("Employé retiré", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  )
};


export const useGetActiviteEntretien = (employeeId?: number) => {
  const promise = () =>
    fetchApi("/api/employee_entretien_activites", { method: "get", query: { Employee: employeeId } });
  return useQuery(["activites_entretien", { employeeId }], promise, {
    enabled: !!employeeId,
  });
};

export const useGetEmployeesCompetences = (employeeId?: number) => {
  const promise = () => fetchApi("/api/employee_competences", { method: "get", query: { Employee: employeeId || -1 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employeeCompetences", { employeeId }], promise, {
    enabled: !!employeeId,
  });
};

export const useGetAllActiviteEmploye = (employeeId:number)=>{
  const promise = () => fetchApi("/api/employee_activites", { method: "get", query: { Employee: employeeId || -1 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employee_activites", { employeeId }], promise, {
    enabled: !!employeeId && employeeId!==0,
  });
}

export const useAddEmployeeActivite = (employeeId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postEmployeeActiviteCollection", "requestBody">) => {
      return fetchApi("/api/employee_activites", { method: "post", json: data });
    },
    {
      onSuccess: (newEmplActi) => {
        queryClient.invalidateQueries(["employeeCompetences", { employeeId }]);
        queryClient.setQueryData(["activites_employee", newEmplActi.id], newEmplActi);
        toast.success("Activité ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useUpdateNiveauActivite = (employeeId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { niveau: number; idEmployeeActivite: number }) => {
      return fetchApi("/api/employee_activites/{id}", {
        method: "put",
        params: { id: data.idEmployeeActivite + "" },
        json: data,
      });
    },
    {
      onSuccess: (newEmplActi) => {
        queryClient.invalidateQueries(["employeeCompetences", { employeeId }]);
        queryClient.setQueryData(["activites_employee", newEmplActi.id], newEmplActi);
        //toast.success("Activité modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
export const useDeleteActivite = (employeeId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (idEmployeeActvite: number) => {
      return fetchApi("/api/employee_activites/{id}", { method: "delete", params: { id: idEmployeeActvite + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["employeeCompetences", { employeeId }]);
        queryClient.invalidateQueries(["activites_employee"]);
        toast.success("Activité supprimée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteActiviteEmployee  = (employeeId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (idActivite: number) => {
      return fetchApi("/api/employee_activites/delete_activite",
        { method: "post", query: { idEmployee: employeeId, idActivite:idActivite }, json: {} });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["employeeCompetences", { employeeId }]);
        queryClient.invalidateQueries(["activites_employee"]);
        toast.success("Activité supprimée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetMobTime = (employeeId?: number) => {
  const promise = () =>
    fetchApi("/api/employee_mobilite_times", { method: "get", query: { Employee: employeeId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["mobilite_time", { employeeId }], promise, {
    enabled: !!employeeId,
  });
};

export const useGetBadges = () => {
  const promise = () => fetchApi("/api/badges", { method: "get" });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["badges"], promise, {});
};

export const useGetBadgesEmployee = (employeeId?: number) => {
  const promise = () => fetchApi("/api/employee_badges", { method: "get", query: { Employee: employeeId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["badges", { employeeId }], promise, {
    enabled: !!employeeId,
  });
};

export const useAddBadgeEmployee = (employeeId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { idBadge: number; idMemberCreate: number }) => {
      return fetchApi("/api/employee_badges", {
        method: "post",
        json: {
          Badge: "/api/badges/" + data.idBadge,
          Employee: "/api/employees/" + employeeId,
          MemberCreate: "/api/members/" + data.idMemberCreate,
        },
      });
    },
    {
      onSuccess: (newBadges) => {
        queryClient.invalidateQueries(["badges", { employeeId }]);
        toast.success("Badge ajouté !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteBadge = (employeeId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (idBadge: number) => {
      return fetchApi("/api/employee_badges/{id}", {
        method: "delete",
        params: { id: idBadge + "" },
      });
    },
    {
      onSuccess: (newBadges) => {
        queryClient.invalidateQueries(["badges", { employeeId }]);
        toast.success("Badge supprimé !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetXp = (employeeId?: number) => {
  const promise = () => fetchApi("/api/employee_xps", { method: "get", query: { Employee: employeeId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["experiences", { employee: employeeId }], promise, {
    enabled: !!employeeId,
  });
};

export const useAddXpPro = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postEmployeeXpCollection", "requestBody">) => {
      return fetchApi("/api/employee_xps", { method: "post", json: data });
    },
    {
      onSuccess: (newXp) => {
        queryClient.invalidateQueries(["experiences"]);
        queryClient.setQueryData(["experiences", newXp.id], newXp);
        toast.success("Expérience ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useMutateXpPro = (employeeXpId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putEmployeeXpItem", "requestBody">) => {
      return fetchApi("/api/employee_xps/{id}", { method: "put", json: data, params: { id: employeeXpId + "" } });
    },
    {
      onSuccess: (newExperience) => {
        queryClient.invalidateQueries(["experiences"]);
        queryClient.setQueryData(["experiences", newExperience.id], newExperience);
        toast.success("Expérience modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteXpPro = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (employeeXpId: number) => {
      return fetchApi("/api/employee_xps/{id}", { method: "delete", params: { id: employeeXpId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["experiences"]);
        toast.success("Expérience supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetFI = (employeeId?: number) => {
  const promise = () => fetchApi("/api/employee_f_is", { method: "get", query: { Employee: employeeId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formation_initiales", { employeeId }], promise, {
    enabled: !!employeeId,
  });
};

export const useMutateFi = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putEmployeeFIItem", "requestBody">) => {
      return fetchApi("/api/employee_f_is/{id}", { method: "put", json: data, params: { id: id + "" } });
    },
    {
      onSuccess: (newFi) => {
        queryClient.invalidateQueries(["formation_initiales"]);
        queryClient.setQueryData(["formation_initiales", newFi.id], newFi);
        toast.success("Formation modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddFi = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postEmployeeFICollection", "requestBody">) => {
      return fetchApi("/api/employee_f_is", { method: "post", json: data });
    },
    {
      onSuccess: (newFi) => {
        queryClient.invalidateQueries(["formation_initiales"]);
        queryClient.setQueryData(["formation_initiales", newFi.id], newFi);
        toast.success("Formation ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteFi = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (employeeFiId: number) => {
      return fetchApi("/api/employee_f_is/{id}", { method: "delete", params: { id: employeeFiId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formation_initiales"]);
        toast.success("formation supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetMobiliteSaved = (employeeId?: number) => {
  const promise = () =>
    fetchApi("/api/employee_mobilite_saves", { method: "get", query: { Employee: employeeId + "" } });
  return useQuery(["mobilite_saves", { employeeId }], promise, {
    enabled: !!employeeId,
  });
};

export const useMutateMobiliteSaved = (employeeId?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (metierId: number) => {
      return fetchApi("/api/employee_mobilite_saves", {
        method: "post",
        json: { Employee: "/api/employees/" + employeeId, Metier: "/api/metiers/" + metierId },
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["mobilite_saves", { employeeId }]);
      },
      onError: (error: any) => {
        //toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetMobilitesInternes = (employeeId?: number) => {
  const promise = () => fetchApi("/api/employee_mobilite_internes", { method: "get", query: { Employee: employeeId || -1 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["mobilites_interne", { employeeId }], promise, {
    enabled: !!employeeId,
  });
};

export const useGetMobilites = (employeeId?: number) => {
  const promise = () => fetchApi("/api/employee_mobilites", { method: "get", query: { Employee: employeeId || -1 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["mobilites", { employeeId }], promise, {
    enabled: !!employeeId,
  });
};

export const useGetMobilite = (idMobilite: number) => {
  const promise = () => fetchApi("/api/employee_mobilites/{id}", { method: "get", params: { id: idMobilite + "" } });
  return useQuery(["mobilite", { idMobilite }], promise, {
    enabled: !!idMobilite,
  });
};

export const useRefreshMobilites = (refresh: 1 | 0, employeeId?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return fetchApi("/api/employee_mobilites", {
        method: "get",
        query: { Employee: employeeId || -1, Refresh: refresh },
      });
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["mobilites", { employeeId }], data);
        queryClient.invalidateQueries(["mobilite_time", { employeeId }]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetFormationToMetier = (employeeId?: number, metierId?: number) => {
  const promise = () =>
    fetchApi("/api/formation_metiers/{id}", {
      method: "get",
      params: { id: employeeId + "" },
      query: { Metier: metierId || -1 },
    });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["formation_metiers", { employeeId, metierId }],
    promise,
    {
      enabled: !!employeeId && !!metierId,
    }
  );
};

export const useGetOFAutoFormation = (employeeId?:number)=>{
  const promise = ()=>
    fetchApi("/api/employee_auto_formations/{id}",{
      method: "get",
      params: { id: employeeId + "" },
    });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["auto_formation", employeeId],
    promise,
    {
      enabled: !!employeeId,
    }
  );
}


export const useGetEmployeesByActivity = (activityID: string) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/employee_activites/by_competence/{id}", {
    method: "get",
    params: { id: memberId + "" },
    query: { activity: activityID },
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employees_activites_custom", { memberId }, activityID], promise, {
    enabled: !!activityID,
  });
};

export const useGetEmployeesNeedsForm = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/employee_needs_forms_to_analyze/{id}", { method: "get" , params: { id: memberId }});
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employees_needs_form_toanalyze", { memberId }], promise, {
    enabled: !!memberId,
  });
};

export const useGetByCompetenceEmployeeNeedsForm = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/employee_needs_forms_count_activites_to_analyze/{id}", { method: "get" , params: {id: memberId}});
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employees_cnt_act_needs_form_toanalyze", { memberId }], promise, {
    enabled: !!memberId,
  });
};

export const useGetEmployeesCountActivityLevel = (employeeID: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/employee_activites/count_activite_by_level/{id}", {
    method: "get",
    params: { id: employeeID + "" },
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employees_count_activite_by_level", { memberId }, employeeID], promise, {
    enabled: !!memberId && !!employeeID,
  });
};

export const useGetEmployeeCompareUserMetierActivityLevel = (employeeID: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/user_metier_activity/compare_level_by_employee/{id}", {
    method: "get",
    params: { id: employeeID + "" },
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["compare_level_user_metier_activity", { memberId }, employeeID], promise, {
    enabled: !!memberId && !!employeeID,
  });
};

export const useGetEmployeeScoreCompetence = (employeeID: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/proximite_score_employee/{id}", {
    method: "get",
    params: { id: employeeID + "" },
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["proximite_score_employee", { memberId }, employeeID], promise, {
    enabled: !!memberId && !!employeeID,
  });
};

export const useGetCustomEmployeeFi = (employeeID: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/employee_f_is/{id}", {
    method: "get",
    params: { id: employeeID + "" },
  });
  return useQuery<any>(["custom_employee_f_is_info", { memberId }, employeeID], promise, {
    enabled: !!memberId && !!employeeID,
  });
};
