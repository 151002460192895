import styled from "styled-components";
import {useMemo, useState} from "react";
import {
    components,
    useGetBesoinsFormationEntreprise,
    useGetFormationPlanningMember
} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {ModalFormationActivite} from "../../Components/ModalFormationActivite";
import {WrapResultBesoin} from "../../Components/WrapResultBesoin";
import {
    MasterBlock,
    OneActiviteMaster,
    OneBlockMaster,
    OneEmployeeMaster,
    OneItemMaster
} from "../../Components/OneBlockMaster";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface BesoinsByEquipeCtrlProps{
    className?:string;
}


const BesoinsByEquipeCtrl = (props:BesoinsByEquipeCtrlProps)=>{
    const [search, setSearch] = useState<string>('')
    const [libelleComp, setLibelleComp] = useState<any>([]);
    const [detail, setDetail] = useState<any>([]);
    const BesoinsQuery = useGetBesoinsFormationEntreprise();
    const [openD, setOpenD] = useState(false);
    const [actiCurr, setActiCurr] = useState<{id:number, libelle:string, niveau:number}|null>(null)
    const [ExpertsCurr, setExpertsCurr] = useState<ExpertOne[]>([])
    const PlanificationQuery = useGetFormationPlanningMember();
    const listPlanification = useMemo(()=>{
        const list:{idEmployee:number, idActi:number}[] = [];
        if(PlanificationQuery.data && !PlanificationQuery.isLoading && !PlanificationQuery.isError){
            PlanificationQuery.data.forEach(p=>{
                list.push({idEmployee:(p.Employee ? p.Employee.id||0 : 0), idActi:(p.Activite ? p.Activite.id||0 : 0)})
            })
        }
        return list;
    }, [PlanificationQuery.data, PlanificationQuery.isLoading, PlanificationQuery.isError])
    const myList = useMemo(()=>{
        const list:MasterBlock[] = [];
        if(BesoinsQuery.data){
            const regex = new RegExp(""+search+"", 'gi');
            const Employees:(components["schemas"]["Employee-read.BesoinFormationEntreprise_read.Activite_read.employeExpert_read.Employee.detailed_read.EmployeeNeedsForm_read.Competence_read.Famille_read.Domaine_read.Services.Detailed_read.UserMetier_read.Metier"])[] = [];
            const EmployesNF:OneEmployeeMaster[] = [];
            BesoinsQuery.data.forEach(b=>{
                const Emps = b.EmployeeNeedFormation;

                if(Emps) {
                    Emps.forEach(e => {
                        if(e.Employees) {
                            EmployesNF.push({Employee: {id: e.Employees?.id || 0, name: `${e.Employees.prenom} ${e.Employees.nom}`}, niveau:e.niveau||0, niveauTarget:e.cible||0, idMaster:e.Employees?.Service?.id||0, Activite: {
                                    id:e.Activite?.id || 0,
                                    libelle:e.Activite?.libelle||'',
                                    idCompetence:e.Activite?.Competence?.id||0
                                },
                                Competence:{id:e.Activite?.Competence?.id||0, libelle:e.Activite?.Competence?.libelle||''}
                            })
                            Employees.push(e.Employees)
                        }
                    })
                }
            })

            const Services:{id:number, libelle:string}[] = []
            Employees.forEach(e=> {
                if(e.Service && e.Service.id && e.Service.libelle){
                    Services.push({id : e.Service.id, libelle:e.Service.libelle})
                }
            })
            const ServicesSSD = Services.reduce((acc:{id:number, libelle:string}[], item:{id:number, libelle:string})=>acc.map(a=>a.id).indexOf(item.id) === -1 ? [...acc, item] : acc, [])
            ServicesSSD.forEach(s=>{
                let nb = 0;
                //if(search === '' || s.libelle.match(regex)) {
                    const EmloyeesConcernedService: OneEmployeeMaster[] = EmployesNF.filter(e => e.idMaster === s.id)
                    const CompetencesConcerned = EmloyeesConcernedService.map(e=>e.Competence);
                    const listCompetence = CompetencesConcerned.reduce((acc:{id:number, libelle:string}[], item:{id:number, libelle:string})=>acc.map(a=>a.id).indexOf(item.id) === -1 ? [...acc, item] : acc, [])
                    setLibelleComp((prev: any) => [...prev, listCompetence])
                    const resultSearch = listCompetence.filter(c => c.libelle.match(regex))

                   const TabComp:OneItemMaster[] = [];
                    resultSearch.forEach(c=>{
                    const EmloyeesConcernedCompetence: OneEmployeeMaster[] = EmloyeesConcernedService.filter(e => e.Competence.id === c.id)
                    const ActiviteConcerned = EmloyeesConcernedCompetence.map(e=>e.Activite);
                    const listActivite = ActiviteConcerned.reduce((acc:{id:number, libelle:string}[], item:{id:number, libelle:string})=>acc.map(a=>a.id).indexOf(item.id) === -1 ? [...acc, item] : acc, [])
                    const TabActivites:OneActiviteMaster[] = [];
                    listActivite.forEach(a=>{
                        const EmployeesConcernedActivite: OneEmployeeMaster[] = EmloyeesConcernedCompetence.filter(e => e.Activite.id === a.id)
                        nb +=EmployeesConcernedActivite.length;
                        TabActivites.push(
                            {Activite:{id:a.id, libelle:a.libelle, idCompetence:c.id}, EmployeesInActi:EmployeesConcernedActivite},
                        )
                    })
                        TabComp.push({Competence:{id:c.id, libelle:c.libelle}, Activites:TabActivites})
                    })
                    list.push({Master:{id:s.id, libelle:s.libelle}, listCompetence:TabComp, nb:nb})
               // }
            })
        }
      return list;
    }, [BesoinsQuery.data, search])
    const SwitchOpen = ()=>{
        setOpenD(o=>!o);
    }
    const ClickActi = (item:{id:number, libelle:string, niveau:number})=>{
        const Besoins = BesoinsQuery.data?.find(b=>b.activite?.id === item.id);
        if(Besoins){
            const ExpertsThis = Besoins.Experts;
            if(ExpertsThis) {
                setExpertsCurr(ExpertsThis.map(e => {
                    return {
                        employee: {id: (e.employee?.id||0), prenom: (e.employee?.prenom||''), nom:  (e.employee?.nom||''), service: ''},
                        niveau: 0
                    }
                }))
            }
        }
        setActiCurr(item);
        SwitchOpen();
    }
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [selectedCompetenceItems, setSelectedCompetenceItems] = useState<any[]>([]);

    const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const isSelected = e.target.checked;
      const value = e.target.value;
      if (isSelected) {
        setSelectedItems([...selectedItems, value]);
        return
      }
      setSelectedItems((prevData) => prevData.filter((item) => item !== value ));
    };
    const [alert, setAlert] = useState('')
    const tabEmp: any[] = []
    myList.forEach(value => value.listCompetence.map((item: any) => item.Activites.map((val: any) => val.EmployeesInActi.map((emp: any) => {
      if (emp.Activite && emp.Employee) {
        tabEmp.push({empID: emp.Employee.id, actID: emp.Activite.id})
      }
    }))))
    const checkAllHandler =  () => {
      if (selectedItems.length === tabEmp.length) {
        setSelectedItems([])
        setSelectedCompetenceItems([])
        return
      }
      const IDs = tabEmp.map((item)=> JSON.stringify(item));
      const compIDS: any[] = []
      myList.forEach((item) => item.listCompetence.map(itemE => compIDS.push(itemE.Competence.id)))
      setSelectedItems(IDs)
      setSelectedCompetenceItems(compIDS)
    }
    const checkboxCompetenceHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = e.target.checked;
    const value = parseInt(e.target.value, 10);
    const comps: any[] = []
    const tab: any[] = []
    if (isSelected) {
      setSelectedCompetenceItems([...selectedCompetenceItems, value]);
      myList.forEach(el => {
        const filteredCompetences = el.listCompetence.filter(c => c.Competence.id === value);
        filteredCompetences.length > 0 && comps.push(filteredCompetences)
      });
      const c = comps.reduce((acc: any, curr: any) => acc.concat(curr), [])
      for (let i =0; i < c.length; i++) {
        c[i].Activites.forEach((item: any) => item.EmployeesInActi.map((itemE: any) => {
          const el = `${itemE.Employee.id}_${itemE.Activite.id}`
          const id = document.getElementById(el) as HTMLInputElement
          id.checked =  true
          const dataVal = {empID: itemE.Employee.id, actID: itemE.Activite.id}
          tab.push(JSON.stringify(dataVal))
        }))
      }
      setDetail((prevDetail: any) => [...prevDetail, { id: value, list: tab }]);
      setSelectedItems([...selectedItems, ...tab])

      return
    }
    setSelectedCompetenceItems((prevData) => prevData.filter((item) => item !== value ));
    const wn = detail.filter((item: any) => item.id === value)
    wn.map((el: any) => el.list.map((item: any) => {
      const obj = JSON.parse(item)
      const el = `${obj.empID}_${obj.actID}`
      console.log(el)
      const id = document.getElementById(el) as HTMLInputElement
      id.checked = false
      setSelectedItems((prevData) => prevData.filter((mySelectedItem) => mySelectedItem !== item ));
    }))
    setDetail((prevDetail: any) => prevDetail.filter((item: any) => item.id !== value));
  }

    const btnTitle = selectedItems.length === tabEmp.length ? 'Désélectionner' : 'Tout sélectionner'
    const queryClient = useQueryClient();
    const mutationItems = useMutation({
      mutationFn: (items) => {
        return fetch(`${process.env["REACT_APP_API_URL"]}/api/employee_needs_form_set_toanalyze`, {
          method: 'POST',
          body: JSON.stringify({data: items}),
          headers: {"Content-Type": "application/json", "Accept": "application/json"},
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["employees_needs_form_toanalyze"]);
        setSelectedItems([])
        setSelectedCompetenceItems([])
        setAlert('Les collaborateurs sélectionnés se retrouvent à présent sur le tableau "Analyse et priorisation"')
        setTimeout(() => setAlert(''), 5000)
      }
    })
    const dropList = useMemo(() => {
      if (search && libelleComp.length > 0) {
        const regex = new RegExp(""+search+"", 'gi');
        const data = libelleComp.reduce((acc: any, curr: any) => {
          curr.forEach((item: {id: number, libelle: string}) => {
            if (!acc.some((existingItem: {id: number, libelle: string}) => existingItem.id === item.id && existingItem.libelle === item.libelle)) {
              acc.push(item);
            }
          });
          return acc;
        }, []);
        return data.filter((el: any) => el.libelle.match(regex))
      }
      return []
    }, [search, libelleComp])

    return (
        <div className={`besoins_Equipe ${props.className}`}>
            {BesoinsQuery.isLoading ? (
                <MbjLoading />
            ) : BesoinsQuery.isError || !BesoinsQuery.data ? (
                <p>Erreur ...</p>
            ) : (
                <WrapResultBesoin
                  alert={alert}
                  onCheckAllHandler={checkAllHandler}
                  compItems={selectedItems}
                  onSendEmps={mutationItems.mutate}
                  btnTitle={btnTitle}
                  search={search}
                  setSearch={setSearch}
                  dropList={dropList}
                >
                    {myList.map(item=>(
                        <OneBlockMaster
                          selectedItems={selectedItems}
                          onCheckboxHandler={checkboxHandler}
                          listPlanification={listPlanification}
                          key={`one_bsoiSev${item.Master.id}`}
                          Blocks={item} ClickActi={ClickActi}
                          onCheckboxCompetenceHandler={checkboxCompetenceHandler}
                          selectedCompetenceItems={selectedCompetenceItems}
                        />
                    ))}
                </WrapResultBesoin>

            )}
            <ModalFormationActivite
                open={openD}
                closeMe={SwitchOpen}
                activite={actiCurr ? actiCurr : {id:0, libelle:'nc', niveau:0}}
                Experts={ExpertsCurr}
            />
        </div>
    )
}
export const BesoinsByEquipe = styled(BesoinsByEquipeCtrl)`
  padding: 1% 0;
  height: 100%;

`
