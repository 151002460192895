import styled from "styled-components";
import {Navigate, Route, Routes} from "react-router-dom";
import {InternDesktopLayout, MbjLink, MbjSectionMenu} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {NotFound} from "../../../components/NotFound/NotFound";
import {CvThequeRooter} from "./02_CvTheque/CvThequeRooter";
import ProfilsRetenus from "./03_ProfilsRetenus/ProfilsRetenus";
import Analyse from "./04_Analyse/Analyse";
import {RecherchesRooter} from "./01_Recherches/RecherchesRooter";
import {useGetRight} from "@mbj-front-monorepo/data-access";

interface RecrutementsRooterCtrlProps{
    className?:string;
}

const RecrutementsRooterCtrl = (props:RecrutementsRooterCtrlProps)=>{
    const {isLoading:loadRightR_recherche, isAuth:isAuthR_recherche} = useGetRight("Dynamique de l'effectif", "read", "Recrutements", "Recherches", "")
    const {isLoading:loadRightR_cv, isAuth:isAuthR_cv} = useGetRight("Dynamique de l'effectif", "read", "Recrutements", "cvTeque", "")
    const {isLoading:loadRightR_profil, isAuth:isAuthR_profil} = useGetRight("Dynamique de l'effectif", "read", "Recrutements", "profil", "")
    const {isLoading:loadRightR_analyse, isAuth:isAuthR_analyse} = useGetRight("Dynamique de l'effectif", "read", "Recrutements", "Analyse", "")
    return (
        <div className={"Recrutement_rooter" + props.className}>
            <Routes>
                <Route
                    element={
                        <InternDesktopLayout
                            type="Entreprise"
                            mbjSectionMenu={
                                <MbjSectionMenu>
                                    {isAuthR_recherche &&
                                        <MbjLink to="recherches">Recherches</MbjLink>
                                    }
                                    {isAuthR_cv &&
                                        <MbjLink to="cvtheque">Cvthèque</MbjLink>
                                    }
                                    {isAuthR_profil &&
                                        <MbjLink to="profils">Profils retenus</MbjLink>
                                    }
                                    {isAuthR_analyse && process.env["REACT_APP_DEFAULT_THEME"] !== 'sqyTheme' &&
                                        <MbjLink to="analyse">Analyse recrutement</MbjLink>
                                    }
                                </MbjSectionMenu>
                            }
                        />
                    }
                >
                    <Route path="" element={<Navigate to="recherches" />} />
                    <Route path="recherches/*" element={<RecherchesRooter />} />
                    <Route path="/cvtheque/*" element={<CvThequeRooter />} />
                    <Route path="/profils" element={<ProfilsRetenus />} />
                    <Route path="/analyse" element={<Analyse />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </div>
    );
}

export const RecrutementsRooter = styled(RecrutementsRooterCtrl)`
    height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

