import styled from "styled-components";
import {useGetRight} from "@mbj-front-monorepo/data-access";

interface OneMetierEvolutionCtrlProps{
  className?:string;
}

const OneMetierEvolutionCtrl = (props:OneMetierEvolutionCtrlProps)=>{
  const {isLoading:loadRightR_evo, isAuth:isAuthR_evo} = useGetRight("Métiers", "write", "MetierEntreprise", "OneMetier", "evo")
  return (
    <div className={`evolution_metier ${props.className}`}>
      <h2>Page en cours de construction</h2>
    </div>
  )
}

export const OneMetierEvolution = styled(OneMetierEvolutionCtrl)`

`
