import styled from "styled-components";

interface OneMetierMarcheCtrlProps{
  className?:string;
}

const OneMetierMarcheCtrl = (props:OneMetierMarcheCtrlProps)=>{
  return (
    <div className={`marche_metier ${props.className}`}>
      <h2>Page en cours de construction</h2>
    </div>
  )
}

export const OneMetierMarche = styled(OneMetierMarcheCtrl)`

`
