import styled from "styled-components";
import {MbjCardBack, MbjErrorData, MbjLoading, MbjNoResult} from "@mbj-front-monorepo/ui";
import {useParams} from "react-router-dom";
import {useGetBadges, useGetBadgesEmployee, useGetEmployeesCompetences} from "@mbj-front-monorepo/data-access";
import {BadgeCard} from "../Badges/BadgeCard";
import {useMemo} from "react";
import {StickerBadgeResume} from "../Badges/components/StickerBadgeResume";

interface OneCollaborateurBadgesCtrlProps{
    className?:string;
    readonly:boolean;
}

export interface BadgeActiStick{
    id:number,
    libelle:string,
    actif:boolean,
    ActivitesLegacy?:{id:number, libelle:string}[];
    description:string;

}

const OneCollaborateurBadgesCtrl = (props:OneCollaborateurBadgesCtrlProps)=>{
    const { id } = useParams();
    const BadgesQuery = useGetBadges();
    const employeeBadgesQuery = useGetBadgesEmployee(id ? parseInt(id) : -1);
    const EmployeeCompQuery = useGetEmployeesCompetences(id ? parseInt(id) : 0);

    const listExpert:{id:number, libelle:string, niveau:number}[] = useMemo(()=>{
        if(EmployeeCompQuery.data){
            const CompetencesNiveau = EmployeeCompQuery.data.filter(e=>e.Niveau && e.Niveau >=4);
            console.log(CompetencesNiveau);


            return EmployeeCompQuery.data.filter(e=>(e.Niveau||0)>=4).map(e=>{
                return {id:e.Competence?.id||0, libelle:(e.Competence?.libelle||''), niveau:e.Niveau ? e.Niveau : 0}
            }).sort((a, b)=>a.niveau > b.niveau ? -1 : 1)
        } else {
            return [];
        }
    }, [EmployeeCompQuery.data])
    const isActifBadge = (id:number)=>{
        if(!employeeBadgesQuery.data){
            return false;
        } else{
            const my = employeeBadgesQuery.data.find(ebq=>ebq.Badge?.id === id);
            if(my){
                return true;
            } else {
                return false;
            }
        }
    }
    const listBadges = useMemo(():BadgeActiStick[]=>{
        if(!BadgesQuery.data){
            return [];
        } else {
            const tab:BadgeActiStick[] = [];
            BadgesQuery.data.map(b=>{
                const BadgeActi = b.badgeActivites;
                let myActi:{id:number, libelle:string}[] = [];
                if(BadgeActi) {
                    myActi = BadgeActi.map(ba => ba.Activite).map(a => {
                        return {id: a ? a.id || 0 : 0, libelle: a ? a.libelle || '' : ''}
                    })
                }
                tab.push({
                    id:b.id||0,
                    libelle:b.libelle||'',
                    actif:isActifBadge(b.id||0),
                    ActivitesLegacy:myActi,
                    description:b.description||''
                })
            })
            return tab;
        }
    }, [BadgesQuery.data, isActifBadge])
    return (
        <MbjCardBack title={"Badges"}>
            {BadgesQuery.isLoading || employeeBadgesQuery.isLoading || EmployeeCompQuery.isLoading ? <MbjLoading/> : employeeBadgesQuery.isError ? <MbjErrorData/>:
                <div className={`wrap_badger`}>
                    <StickerBadgeResume readonly={props.readonly} Badge={{id:0, libelle:`Badges Expert ${listExpert.length}`, actif:listExpert.length>0, ActivitesLegacy:[], description:'Correspond aux compétences du collaborateur avec une niveau d\'au moins 4 sur 5'}} switchable={false}/>
                    {listBadges.map((item:BadgeActiStick)=>(
                      <StickerBadgeResume readonly={props.readonly} Badge={item} switchable={true} key={`onS${item.id}`}/>
                    ))
                    }
                </div>
            }
        </MbjCardBack>
    )
}

export const OneCollaborateurBadges = styled(OneCollaborateurBadgesCtrl)`

`
