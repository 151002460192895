import * as React from "react";

import {
  MbjCardClassic,
  MbjFlex,
  MbjLoading,
  MbjWidgetClassic,
  MbjLabel, MbjButton,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import {
useGetEntretienRecapNoSignEmployees, useGetEntretienRecapNoSignResp,
} from "@mbj-front-monorepo/data-access";
import { useState } from "react";

interface EntretienStatsCtrlProps {
  className?: string;
}



function EntretienStatsCtrl(props: EntretienStatsCtrlProps) {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const noSignEmployees = useGetEntretienRecapNoSignEmployees()
  const noSignResp = useGetEntretienRecapNoSignResp()
  const handlerCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = e.target.checked;
    const value = parseInt(e.target.value);

    if( isSelected ){
      setSelectedItems( [...selectedItems, value ] )
    }else{
      setSelectedItems((prevData)=>{
        return prevData.filter((id)=>{
          return id!==value
        })
      })
    }
  }


  return (
    <div className={"PriorisationDetail" + props.className}>
      <MbjFlex direction={"column"}>
        <MbjWidgetClassic title={"Liste des collaborateurs n'ayant pas signé leur évaluation"}>
          <MbjCardClassic title="Informations" basis={"100%"} >
            <div className="noSigns">
              {noSignEmployees.isLoading ? <MbjLoading /> : noSignEmployees.data?.length > 0 && <div className="noSignEmployees">
                <MbjLabel className="mblabel">Salariés </MbjLabel>
                {noSignEmployees.data.map((emp: any) => <div className="user">
                    <input type="checkbox" value={emp.id} onChange={handlerCheckBox} checked={selectedItems.includes( emp.id )}/>
                    <label>{emp.nom + ' ' + emp.prenom}</label>
                  </div>
                )}
              </div>}
              {noSignResp.isLoading ? <MbjLoading /> : noSignResp.data?.length > 0 && <div className="noSignResp">
                <MbjLabel className="mblabel">Responsables </MbjLabel>
                {noSignResp.data.map((resp: any) => <div className="user">
                    <input type="checkbox" value={resp.id} onChange={handlerCheckBox} checked={selectedItems.includes( resp.id )}/>
                    <label>{resp.nom}</label>
                  </div>
                )}
              </div>}
            </div>
            {(noSignEmployees.data?.length > 0 || noSignResp.data?.length  > 0) && <MbjButton themeColor={"primary"}><MbjLabel>Signer</MbjLabel></MbjButton> }
          </MbjCardClassic>
        </MbjWidgetClassic>
      </MbjFlex>
    </div>
  );
}

export const EntretienStats = styled(EntretienStatsCtrl)((props) => ({
  padding: "1% 2%",
  ".mblabel": {
    marginBottom: "10px"
  },
  ".noSigns" : {
    display: "flex",
    justifyContent: "space-between",
  },
  ".Infos": {
    padding: "0.5em 1em",
  },
  ".user label" : {
    marginLeft: "10px",
     lineHeight: "1.6"
  },
  ".InformationsCardContent": {
    padding: "4px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  ".metrixContent": {
    fontSize: "1.4rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
