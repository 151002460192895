import styled from "styled-components";
import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { GetElementType } from "@mbj-front-monorepo/utils";
import {
  createSelectColumn,
  MbjLoading,
  MbjScrollArea,
  MbjTableV2,
} from "@mbj-front-monorepo/ui";
import React from "react";
import { useGetEntretienRecapNoSignResp } from "@mbj-front-monorepo/data-access";

interface EntretienRespRecapCtrlProps {
  className?:string;
}


const EntretienRespRecapCtrl = (props: EntretienRespRecapCtrlProps) => {
  const noSignManager = useGetEntretienRecapNoSignResp()
  const columnHelper = createColumnHelper<GetElementType<typeof noSignManager.data>>();

  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.accessor((row: any) => row.nom, {
      header: "Nom",
    }),
    columnHelper.accessor((row: any) => row.role, {
      header: "Type",
    }),
    columnHelper.display({
      header: 'Actions',
      id: 'actions',
    }),
  ];
  const table = useReactTable({
    data: noSignManager?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    enableRowSelection: true,
  })
  return (
    <div className={`Priorisation_listing pageStd ${props.className}`}>
      {noSignManager.isLoading ? (
        <MbjLoading />
      ) : noSignManager.isError ? (
        <p>Erreur</p>
      ) : (
        <>
          <div className={"space-between"}>
            <div className={"ResultNumber"}>{table.getRowModel().rows.length > 1 ?  `${table.getRowModel().rows.length} résultats` : `${table.getRowModel().rows.length} résultat`}</div>
          </div>


          <MbjScrollArea overflowX={"auto"}>
            <MbjTableV2 table={table} Multiple={true}/>
          </MbjScrollArea>
        </>
      )}

    </div>
  )
}

export const EntretienRespRecap = styled(EntretienRespRecapCtrl)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 50px;

  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ResultNumber {
    font-size: 20px;
  }

  .btn-actions {
    display: flex;
    justify-content: space-between;
  }

  .btn-actions button:first-child {
    margin-right: 10px;
  }

  .blk-plan {
    margin: 10px 0;
  }

  .plprg {
    margin-bottom: 0.5rem;
  }

  .plprg_select {
    display: block;
    margin: 20px 10px 20px 0;
  }
`
