import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {mbjTheme, orlyTheme, pauTheme, sqyTheme} from "@mbj-front-monorepo/ui";
import {ThemeProvider} from "styled-components";
import React from "react";
import GlobalStyle from "../assets/styles/global";
import {Navigate, Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {PrivateRouteCtrl} from "../components/PrivateRouteCtrl";
import {PublicRouteCtrl} from "../components/PublicRouteCtrl";
import {LegalMentions} from "../pages/LegalMentions/LegalMentions";
import {NotFound} from "../pages/NotFound/NotFound";
import {ForgotPassword} from "../pages/ForgotPassword/ForgotPassword";
import {Login} from "../pages/Login/Login";
import {Entreprise} from "./Entreprise";
import {SignatureEntretienEmployee} from "../pages/SignatureEntretien/SignatureEntretienEmployee";
import {SignatureEntretienResponsable} from "../pages/SignatureEntretien/SignatureEntretienResponsable";


export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      //cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

export const app_version = "2.0.0"

function App() {
  return (
    <ThemeProvider theme={process.env["REACT_APP_DEFAULT_THEME"] === 'pauTheme' ? pauTheme : process.env["REACT_APP_DEFAULT_THEME"] === 'orlyTheme' ? orlyTheme : process.env["REACT_APP_DEFAULT_THEME"] === 'sqyTheme' ? sqyTheme : mbjTheme}>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Routes>
            <Route element={<PrivateRouteCtrl from={"App"} />}>
              <Route path="/entreprise/*" element={<Entreprise/>} />
            </Route>
            <Route element={<PublicRouteCtrl />}>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Route>
            <Route path="/mentions" element={<LegalMentions />} />
            <Route path="/sign/:slug" element={<SignatureEntretienEmployee />} />
            <Route path="/sign_resp/:slug" element={<SignatureEntretienResponsable />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App;
