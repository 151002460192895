import {BadgeActiStick} from "../../Informations/OneCollaborateurBadges";
import {MbjSwitch, MbjSwitchThumb} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {useEffect, useState} from "react";
import styled from "styled-components";
import {useAddBadgeEmployee, useDeleteBadge, useGetMemberMe} from "@mbj-front-monorepo/data-access";
import {useParams} from "react-router-dom";


interface GestionBadgeCtrlProps{
    className?:string;
    Badge:BadgeActiStick;
    isSwitchable:boolean;
}

const GestionBadgeCtrl = ({className,Badge, isSwitchable }:GestionBadgeCtrlProps)=>{
    const [actif, setActif] = useState(Badge.actif);
    const {id} = useParams();
    const addBadgeMutation = useAddBadgeEmployee(id ? parseInt(id) : -1);
    const deleteBadgeMutation = useDeleteBadge(id ? parseInt(id) : -1);
    const memberQuery = useGetMemberMe();
    useEffect(() => {
        setActif(Badge.actif);
    }, [Badge]);
    const handleSwitch = ()=>{
        if(actif){
            setActif(false);
            deleteBadgeMutation.mutate(Badge.id);
        } else {
            const idMemberCreate = memberQuery.data?.id;
            if(idMemberCreate) {
                addBadgeMutation.mutate({idBadge:Badge.id , idMemberCreate });
            }
        }
    }
    useEffect(() => {
        console.log('switch');
    }, [actif]);
    return (
        <div className={`gestionBadge ${className}`}>
            <p className={"description"}>{Badge.description}</p>
            {!isSwitchable ?
                <p>L'acquisition de ce badge est automatique en fonction des caractéristiques du collaborateur</p>:
                <div className={`switch_badge`}>
                    {Badge.ActivitesLegacy && Badge.ActivitesLegacy.length>0 &&
                        <div className={`acti_legacy`}>
                            <strong>L'acquisition de ce badge entraine l'acquisition des compétences suivantes :</strong>
                            <ul>
                                {Badge.ActivitesLegacy?.map(item=>(
                                    <li key={`comp_leg${item.id}`}>{item.libelle}</li>
                                    ))}
                            </ul>
                        </div>
                    }
                    <div style={{display:"flex", justifyContent:"flex-start", alignItems:"centre", gap:"10px"}}>
                        <span>{Badge.actif ? "Le collaborateur détient ce badge" : "Le collaborateur n'a pas ce badge"}</span>
                        <MbjSwitch
                            defaultChecked={Badge.actif}
                            onCheckedChange={handleSwitch}
                        >
                            <MbjSwitchThumb />
                        </MbjSwitch>
                    </div>
                </div>
            }
        </div>
    )
}

export const GestionBadge = styled(GestionBadgeCtrl)`
    padding:1rem;
    .description{
      font-style: italic;
      margin-bottom: 20px;
    }
  ul{
    margin: 20px 0;
    li{
      margin-left: 25px;
    }
  }
`
