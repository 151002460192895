import * as React from "react";

import {
  MbjCardClassic,
  MbjFlex,
  MbjLoading,
  MbjWidgetClassic,
  MbjLabel, MbjButton,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import {
  useAddMoyenFormationPlanning,
  useGetFormationContinueCourtes,
  useGetFormationExpertsListe,
} from "@mbj-front-monorepo/data-access";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";

interface SelectionSolutionCtrlProps {
  className?: string;
  info: any;
  onToggle: any
}

function SelectionSolutionCtrl(props: SelectionSolutionCtrlProps) {
  const actID = +props.info.actID
  const fpID = +props.info.fpID
  const fccofQuery = useGetFormationContinueCourtes(actID)
  const expertsQuery = useGetFormationExpertsListe(actID)
  const mutation = useAddMoyenFormationPlanning(fpID)
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    register,
  } = useForm({
    mode: "onTouched",
  });
  const handleSend = (data: any) => {
    const results = JSON.parse(data.formation)
    results['action'] = true
    mutation.mutate(results, {
      onSuccess: () => {
        props.onToggle()
        queryClient.invalidateQueries(["formation_planning_without_mf"]);
      }
    })
  }

  return (
    <div className={"PriorisationDetail" + props.className}>
      <MbjFlex direction={"column"}>
        <MbjWidgetClassic title={"Sélection de la solution"}>
          <MbjCardClassic title="Informations" basis={"100%"}>
            <div className="Nom">
              <MbjLabel>Nom et prénom du collaborateur </MbjLabel>
              <div className="data">{props.info.fullname }</div>
              <MbjLabel>Activité </MbjLabel>
              <div className="data">{props.info.activite }</div>
            </div>
          </MbjCardClassic>
          <MbjCardClassic title="Sélection de la solution" basis={"100%"}>
            <form className="boxes" onSubmit={handleSubmit(handleSend)}>
              {fccofQuery.isLoading ? <MbjLoading /> : fccofQuery.data?.length > 0 && <div className="inPutFx">
                <h2 className="formationTitle">Organismes de formation pouvant former à cette compétence</h2>
                <div className="detailBox">
                {fccofQuery.data?.map((item: any) =>
                <div className="infchk">
                  <input {...register("formation")} type="radio" value={JSON.stringify({ id: item.id, type: "fccof" })} />
                  <div>{item.libelle + " avec " + item.rs + " à distance " + Math.floor(item.distance) + " km"}</div>
                </div>
              )}</div></div>}
              {expertsQuery.isLoading ?
                <MbjLoading /> : expertsQuery.data?.length > 0 && <div className="inPutFx">
                <h2 className="formationTitle">Experts pouvant former à cette compétence</h2>
                <div className="detailBox">
                {expertsQuery.data?.map((item: any) =>
                  <div className="infchk">
                    <input {...register("formation")} type="radio" value={JSON.stringify({ id: item.id, type: "expert" })} />
                    <div>{item.nom + " " + item.prenom}</div>
                  </div>
                )}</div></div>}
              {(fccofQuery.data?.length > 0 || expertsQuery.data?.length > 0) && <div style={{marginTop: '10px'}}>
                <MbjButton themeColor="primary" size="md">Sélectionner</MbjButton>
              </div>}
              {(fccofQuery.data?.length === 0 && expertsQuery.data?.length === 0) && <p>Aucune solution. Contactez  MBJ.</p>}
            </form>
          </MbjCardClassic>
        </MbjWidgetClassic>
      </MbjFlex>
    </div>
  );
}

export const SelectionSolution = styled(SelectionSolutionCtrl)((props) => ({
  padding: "1% 2%",
  ".detailBox": {
    backgroundColor: "#f5f1f1",
    padding: "1rem"
  },
 ".formationTitle" : {
    color: "#396c71",
    margin: "1rem 0"
  },
  ".infchk": {
    display: "flex",
    margin: "1rem 0"
  },
  ".infchk div": {
    marginLeft: "10px"
  },
  ".boxes": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection:"column"
  },
  ".Infos": {
    padding: "0.5em 1em",
  },
  ".InformationsCardContent": {
    padding: "4px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  ".metrixContent": {
    fontSize: "1.4rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
