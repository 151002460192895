import React from "react";
import styled from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface MbjMobiliteListItemCtrlProps {
  className?: string;
  mobilite?: Operation<"getParticulierMobiliteItem">;
  libelle?:string;
  score?:number;
}

const MbjMobiliteListItemCtrl = (props: MbjMobiliteListItemCtrlProps) => {
  const pc = props.score ? `${(props.score * 100).toFixed()}%` : props.mobilite?.score ? `${(props.mobilite.score * 100).toFixed()}%` : "--";

  return (
    <div className={`LineMetierMobilite ${props.className}`}>
      <div className="name-metier">
        {props.mobilite ?
            <>
              {props.mobilite.Metier?.libelle} - {props.mobilite.Metier?.salaire==-1 ? 'NC' : (Math.round((props.mobilite.Metier?.salaire||0)*100)/100)+"€" }
            </>:
            props.libelle ? props.libelle : ""
        }
      </div>
      <div className="compatibilite">{pc}</div>
    </div>
  );
};

export const MbjMobiliteListItem = styled(MbjMobiliteListItemCtrl)((props) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  paddingRight: "0.25rem",

  ".name-metier": {
    flexGrow: 1,
    paddingRight: "0.5rem",
    color: "black",
    fontWeight: 600,
  },
  ".compatibilite": {
    color: props.theme.primaryLighten,
    fontWeight: "bold",
  },
}));
