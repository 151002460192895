import styled from "styled-components";
import {
  Operation,
  useGetBesoinsFormationEntreprise,
  useGetFormationPlanningMember,
} from "@mbj-front-monorepo/data-access";
import {useMemo, useState} from "react";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {ModalFormationActivite} from "../../Components/ModalFormationActivite";
import {OneBlockCompetence2} from "../../Components/OneBlockCompetence2";
import {WrapResultBesoin} from "../../Components/WrapResultBesoin";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface BesoinsByCompetencesCtrlProps{
  className?:string;
}

interface CompetenceBlock{
  Competence:{id:number, libelle:string},
  list:Operation<"getBesoinFormationEntrepriseCollection">
  nb:number
}

export interface ExpertOne{
  employee:{id:number, prenom:string, nom:string, service:string}
  niveau:number;
}

export interface Priorisation {
  employeeID: number, activityID: number
}

const BesoinsByCompetencesCtrl = (props:BesoinsByCompetencesCtrlProps)=>{
  const PlanificationQuery = useGetFormationPlanningMember();
  const [search, setSearch] = useState<string>('')
  const [detail, setDetail] = useState<any>([]);
  const BesoinsQuery = useGetBesoinsFormationEntreprise();
  const [openD, setOpenD] = useState(false);
  const [actiCurr, setActiCurr] = useState<{id:number, libelle:string, niveau:number}|null>(null)
  const [ExpertsCurr, setExpertsCurr] = useState<ExpertOne[]>([])
  const listPlanification = useMemo(()=>{
    const list:{idEmployee:number, idActi:number}[] = [];
    if(PlanificationQuery.data && !PlanificationQuery.isLoading && !PlanificationQuery.isError){
      PlanificationQuery.data.forEach(p=>{
        list.push({idEmployee:(p.Employee ? p.Employee.id||0 : 0), idActi:(p.Activite ? p.Activite.id||0 : 0)})
      })
    }
    return list;
  }, [PlanificationQuery.data, PlanificationQuery.isLoading, PlanificationQuery.isError])
  const myList = useMemo(()=>{
    const list:CompetenceBlock[] = [];
    if(BesoinsQuery.data){
      const regex = new RegExp(""+search+"", 'gi');
      const Competences = BesoinsQuery.data.map(b=>b.activite?.Competence)
      let CompetencesSSD:any[] = []
      Competences.map(c=>{
        if(CompetencesSSD.map(c=>c.id).indexOf(c?.id) === -1){
          CompetencesSSD.push(c);
        }
        return c;
      })
      CompetencesSSD = CompetencesSSD.sort((a,b)=>a.libelle > b.libelle ? 1 :-1);

      CompetencesSSD.map(c=>{
        const listConcerned = BesoinsQuery.data.filter(b=>b.activite?.Competence?.id === c.id)
        if(search === '' || c.libelle.match(regex)) {
          list.push({
            Competence: {id: c.id, libelle: c.libelle},
            list: listConcerned,
            nb: listConcerned.length
          })
        }
        return c;
      })
    }
    return list;
  }, [BesoinsQuery.data, search])
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectedCompetenceItems, setSelectedCompetenceItems] = useState<any[]>([]);
  const [alert, setAlert] = useState('')
  const SwitchOpen = ()=>{
    setOpenD(o=>!o);
  }
  const ClickActi = (item:{id:number, libelle:string, niveau:number})=>{
    const Besoins = BesoinsQuery.data?.find(b=>b.activite?.id === item.id);
    if(Besoins){
      const ExpertsThis = Besoins.Experts;
      if(ExpertsThis) {
        setExpertsCurr(ExpertsThis.map(e => {
          return {
            employee: {id: (e.employee?.id||0), prenom: (e.employee?.prenom||''), nom:  (e.employee?.nom||''), service: ''},
            niveau: 0
          }
        }))
      }
    }
    setActiCurr(item);
    SwitchOpen();
  }
  const tabEmp: any[] = []
  myList.forEach(value => {
    value.list.map(item => item.EmployeeNeedFormation?.map(itemE => {
      if (itemE.Employees && item.activite) {
        tabEmp.push({empID: itemE.Employees.id, actID: item.activite.id})
      }
    }))
  })

  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = e.target.checked;
    const value = e.target.value;
    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
      return
    }
    setSelectedItems((prevData) => prevData.filter((item) => item !== value ));
  };


  const checkboxCompetenceHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = e.target.checked;
    const value = parseInt(e.target.value, 10);
    const tab: any[] = []

    if (isSelected) {
      setSelectedCompetenceItems([...selectedCompetenceItems, value]);
      const founds = myList.filter(el => el.Competence.id === value)
      founds.map(f =>
        f.list.map(item =>
          item?.EmployeeNeedFormation?.map(itemE => {
              if (itemE.Employees?.id && itemE.Activite?.id) {
                const el = `${itemE.Employees.id}_${itemE.Activite.id}`
                const id = document.getElementById(el) as HTMLInputElement
                id.checked =  true
                const dataVal = {empID: itemE.Employees.id, actID: itemE.Activite.id}
                tab.push(JSON.stringify(dataVal))
              }
            }
          )
        )
      )
      setDetail((prevDetail: any) => [...prevDetail, { id: value, list: tab }]);
      setSelectedItems([...selectedItems, ...tab]);
      return
    }
    setSelectedCompetenceItems((prevData) => prevData.filter((item) => item !== value ));
    const wn = detail.filter((item: any) => item.id === value)

    wn.map((el: any) => el.list.map((item: any) => {
      const obj = JSON.parse(item)
      const el = `${obj.empID}_${obj.actID}`
      console.log(el)
      const id = document.getElementById(el) as HTMLInputElement
      id.checked = false
      setSelectedItems((prevData) => prevData.filter((mySelectedItem) => mySelectedItem !== item ));
    }))
    setDetail((prevDetail: any) => prevDetail.filter((item: any) => item.id !== value));
  };

  const checkAllHandler =  () => {
    if (selectedItems.length === tabEmp.length) {
      setSelectedItems([])
      setSelectedCompetenceItems([])
      return
    }
    const IDs = tabEmp.map((item)=> JSON.stringify(item));
    const compIDS = myList.map(value => value.Competence.id);
    setSelectedItems(IDs)
    setSelectedCompetenceItems(compIDS)
  }
  const queryClient = useQueryClient();
  const mutationItems = useMutation({
    mutationFn: (items) => {
      return fetch(`${process.env["REACT_APP_API_URL"]}/api/employee_needs_form_set_toanalyze`, {
        method: 'POST',
        body: JSON.stringify({data: items}),
        headers: {"Content-Type": "application/json", "Accept": "application/json"},
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["employees_needs_form_toanalyze"]);
      setSelectedItems([])
      setSelectedCompetenceItems([])
      setAlert('Les collaborateurs sélectionnés se retrouvent à présent sur le tableau "Analyse et priorisation"')
      setTimeout(() => setAlert(''), 5000)
    }
  })

  const btnTitle = selectedItems.length === tabEmp.length ? 'Désélectionner' : 'Tout sélectionner'
  const dropListComps = myList.map(item => item.Competence)

  return (
    <div className={`besoins_competences ${props.className}`}>
      <div className={props.className}>
        {BesoinsQuery.isLoading ? (
          <MbjLoading />
        ) : BesoinsQuery.isError || !BesoinsQuery.data ? (
          <p>Erreur ...</p>
        ) : (
          <WrapResultBesoin
            alert={alert}
            setSearch={setSearch}
            search={search}
            onCheckAllHandler={checkAllHandler}
            btnTitle={btnTitle}
            compItems={selectedItems}
            onSendEmps={mutationItems.mutate}
            dropList={dropListComps}
          >
            {myList.map((item, idx:number)=>(
              <OneBlockCompetence2
                competenceID={item.Competence.id}
                listPlanification={listPlanification}
                key={`BlockComp${idx}`}
                titre={item.Competence.libelle + " ("+item.nb+")"}
                list={item.list}
                ClickActi={ClickActi}
                selectedItems={selectedItems}
                selectedCompetenceItems={selectedCompetenceItems}
                onCheckboxHandler={checkboxHandler}
                onCheckboxCompetenceHandler={checkboxCompetenceHandler}
              />
            ))}
          </WrapResultBesoin>
        )}
      </div>
      <ModalFormationActivite
        open={openD}
        closeMe={SwitchOpen}
        activite={actiCurr ? actiCurr : {id:0, libelle:'nc', niveau:0}}
        Experts={ExpertsCurr}
      />
    </div>
  )
}
export const BesoinsByCompetences = styled(BesoinsByCompetencesCtrl)`
  padding: 1% 0;
  height: 100%;

`
