import styled from "styled-components";
import {Navigate, Route, Routes, To} from "react-router-dom";
import {InternSubDesktopLayout} from "@mbj-front-monorepo/ui";
import * as React from "react";
import { Plannification } from "./by_planification/Plannification";
import { SolutionsFormation } from "./by_solution_formation/SolutionsFormation";
import { PlanFormationByFccof } from "./by_plan_formation/by_moyen_formation/PlanFormationByFccof";
import { PlanFormationByExpert } from "./by_plan_formation/by_moyen_formation/PlanFormationByExpert";

interface PlanificationRooterCtrlProps{
  className?:string;
}

const TabSubMenus:{libelle:string, to:To}[] = [
  {libelle: 'Planification', to:"by_planification"},
  {libelle: 'Sélection solution de formation', to:"by_solution_formation"},
  {libelle: 'Formation par organisme de formation', to:"by_moyen_formation_fccof"},
  {libelle: 'Formation par expert', to:"by_moyen_formation_expert"},
]

const PlanificationRooterCtrl = (props:PlanificationRooterCtrlProps)=>{
  return (
    <Routes>
      <Route
        element={
          <InternSubDesktopLayout
            Links={TabSubMenus}
          />
        }
      >
        <Route path="" element={<Navigate to="by_planification" />} />
        <Route path="/by_planification" element={<Plannification />} />
        <Route path="/by_solution_formation" element={<SolutionsFormation />} />
        <Route path="/by_moyen_formation_fccof" element={<PlanFormationByFccof />} />
        <Route path="/by_moyen_formation_expert" element={<PlanFormationByExpert />} />
      </Route>
    </Routes>
  )
}

export const PlanificationRooter = styled(PlanificationRooterCtrl)``
