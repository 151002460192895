import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  MbjAvatar,
  MbjButton,
  MbjDragDropFile,
  MbjFlex,
  MbjModal,
  MbjModalCtrlProps,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";

interface ChangeImageProfilModalCtrlProps extends MbjModalCtrlProps {
  className?: string;
}

function ChangeImageProfilModalCtrl(props: ChangeImageProfilModalCtrlProps) {
  //const mutationLogo = useChangeLogo();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const [image, setImage] = useState<File | null>(null);

  function onSubmit(data: any) {
    console.log(image);
    //mutationLogo.mutate(data.file[0]);
  }

  const { onChange } = register("file");
  return (
    <MbjModal {...props} maxWidth={"70%"} title={"Modifier l'image"}>
      <form onSubmit={handleSubmit(onSubmit)} className="ImageChooseForm">
        <MbjDragDropFile
            key={''}
          accept={"image/png, image/jpeg, image/jpg"}
            full
          onUpload={(files) => {
            setImage(files[0]);
            console.log(files[0]);
          }}
          count={1}
          formats={["png", "jpeg", "jpg"]}
        />

        <MbjSimpleInputContainer errors={errors} label={"Prévisualisation :"}>
          <MbjFlex>
            {!image ? (
              <p>Aucun fichier sélectionné pour le moment</p>
            ) : (
              <MbjAvatar src={URL.createObjectURL(image)} objectFit={"contain"} bg={"#ffffff"} size={"2xl"} />
            )}
          </MbjFlex>
        </MbjSimpleInputContainer>
        <MbjButton alignSelf={"flex-end"}>Enregistrer</MbjButton>
      </form>
    </MbjModal>
  );
}
export const ChangeImageProfilModal = styled(ChangeImageProfilModalCtrl)((props) => ({
  ".ImageChooseForm": {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
}));
