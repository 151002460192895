import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { fetchApi, Operation } from "../../services/fetchApi";
import {toast} from "react-toastify";
import {useGetMemberMe} from "../member";


export const useRegisterParticulierOrly = () => {
  return useMutation(
    (data: Operation<"addOrlyUserCollection", "requestBody">) => {
      return fetchApi("/api/users/add_orly", { method: "post", json: data });
    },
    {
      onSuccess: (result) => {
        console.log(result);
      },
      onError: (error: any) => {
        console.log(error.message);
      },
    }
  );
};

export const useLogin = () => {
  const promise = (body: any) => fetchApi("/api/login", { method: "post", json: body });

  return useMutation<any, Error>(
    (body: any) => {
      return promise(body);
    },
    {
      onSuccess: (result:{token:string|undefined, refresh_token:string|undefined}) => {
        localStorage.setItem("__user_token__", JSON.stringify(result.token).replace(/['"]+/g, ""));
        localStorage.setItem("__user_refreshtoken__", JSON.stringify(result.refresh_token).replace(/['"]+/g, ""));
      },
      onError: (error) => {
        console.log(error.message);
      },
    }
  );
};

export const useChangeDroit = ()=>{
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();

  return useMutation(
      (data: any) => {
        return fetchApi("/api/users/{id}/add_remove_droit", { method: "get", params: { id: data?.id + "" }, query: { idDroit: data.idDroit } });
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["employee"]);
          toast.success("Profil modifié !", {});
        },
        onError: (error: any) => {
          toast.error("Erreur : " + error.message, {});
        },
      }
  );
}

export const useMe = () => {
  const promise = () => fetchApi("/api/me", "get");
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["me"], promise);
};

export const useGetConnected = () => {
  const promise = () => fetchApi("/api/users/{id}/me_single", {params:{id:1+""}});
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["me_connected"], promise);
};

export const useGetUserExpert = (idUser:number) => {
  const promise = () => fetchApi("/api/user_experts/{id}", {params:{id:idUser+""}});
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["user_expert", idUser], promise, {
    enabled:idUser!==0
  });
};

export const useGetOneUser = (id:number)=>{
  const promise = ()=>fetchApi("/api/users/{id}", {method:"get", params: {id:id+""}})
  return useQuery(["user_single", id], promise, {
    enabled:id!==0
  })
}
