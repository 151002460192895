import styled from "styled-components";
import {
  MbjCardBack,
  MbjTable,
  MbjLoading,
  MbjIconButton,
  useModal,
  MbjModal2,
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {
  useGetEntretienRecapNoSignMember
} from "@mbj-front-monorepo/data-access";
import { BiLineChartDown } from "react-icons/bi";
import { EntretienStats } from "./components/EntretienStats";

interface RecapInfoEntretienAnnuelCtrlProps{
  className?:string
}

const titleTab = [
  { libelle: "Nombre de collaborateurs" },
  { libelle: "Nombre de managers" },
  { libelle: "Action" },
];

const RecapInfoEntretienAnnuelCtrl = (props:RecapInfoEntretienAnnuelCtrlProps)=>{
  const recapQuery = useGetEntretienRecapNoSignMember()
  const {isShowing: isShowingDetail, toggle: toggleDetail} = useModal();

  return (
    <MbjCardBack title={"Nombre de salariés et de managers n'ayant pas signé leur évaluation"}>
      <div className={`obj ${props.className}`}>
        <MbjTable titles={titleTab}>
          {recapQuery.isLoading ? (
            <tr>
              <td colSpan={titleTab.length}>
                <MbjLoading />
              </td>
            </tr>
          ) : recapQuery.data?.length > 0 ? recapQuery.data?.map((r: any, idx: number) => (
            <tr key={idx}>
              <td className="isCenter">{r.nombre_employees}</td>
              <td className="isCenter">{r.nombre_responsable}</td>
              <td className="isCenter">
                <MbjIconButton
                  size={"sm"}
                  onClick={toggleDetail}
                  ariaLabel={"Stats"}
                  icon={<BiLineChartDown />}
                  themeColor={"third"}
                  isRound={false}
                />
              </td>
            </tr>
          )) :  (
            <tr>
              <td colSpan={titleTab.length}>
                Aucun résultat
              </td>
            </tr>
          )}
        </MbjTable>
      </div>
      <MbjModal2 open={isShowingDetail} closeMe={toggleDetail} titre={"Informations détaillés"} Wd={"80%"}>
        <div style={{padding:"0 1rem", height:"max-content"}}>
          <EntretienStats />
        </div>
      </MbjModal2>
    </MbjCardBack>

  )
}

export const RecapInfoEntretienAnnuel = styled(RecapInfoEntretienAnnuelCtrl)`
`
