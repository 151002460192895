import styled from "styled-components";
import { Navigate, Route, Routes, To } from "react-router-dom";
import {MatrixCompMetier} from "./MatrixCompMetier/MatrixCompMetier";
import { InternSubDesktopLayout } from "@mbj-front-monorepo/ui";
import * as React from "react";
import { EntretienEmpRecap } from "./Entretien/EntretienEmpRecap";
import { EntretienRespRecap } from "./Entretien/EntretienRespRecap";

interface PilotageRHRooterCtrlProps{
    className?:string;
}

const TabSubMenus:{libelle:string, to:To}[] = [
  {libelle: 'Matrice de compétence', to:"by_matrix"},
  {libelle: 'Suivi des entretiens par collaborateurs', to:"by_entretien_collaborateurs"},
  {libelle: 'Suivi des entretiens par responsable', to:"by_entretien_manager"},
]
const PilotageRHRooterCtrl = ({className}:PilotageRHRooterCtrlProps)=>{
    return (
        <div className={`pilotage_rh pageStd ${className}`}>
            <Routes>
              <Route
                element={
                  <InternSubDesktopLayout
                    Links={TabSubMenus}
                  />
                }
              >
              <Route path="" element={<Navigate to="by_matrix"/>}/>
              <Route path={"/by_matrix"} element={<MatrixCompMetier/>}/>
               <Route path={"/by_entretien_collaborateurs"} element={<EntretienEmpRecap />}/>
               <Route path={"/by_entretien_manager"} element={<EntretienRespRecap />}/>
              </Route>
            </Routes>
        </div>
    )
}

export const PilotageRHRooter = styled(PilotageRHRooterCtrl)`

`
