import styled from "styled-components";
import {useGetAllSearchByMember, useGetRight} from "@mbj-front-monorepo/data-access";
import {useEffect, useMemo, useState} from "react";
import {ItemMenuChoixOnPage, MbjMenuChoixOnPage} from "@mbj-front-monorepo/ui";
import {
    OneCollaborateurMobiliteExterne
} from "../../../Collaborateurs/OneCollaborateur/Mobilites/MobilitesExterne/OneCollaborateurMobiliteExterne";
import {
    OneCollaborateurMobiliteInterne
} from "../../../Collaborateurs/OneCollaborateur/Mobilites/MobilitesInterne/OneCollaborateurMobiliteInterne";
import * as React from "react";
import {WrapperSearchs} from "./components/WrapperSearchs";
import {NewRecherche} from "./NewRecherche";

interface RecherchesCtrlProps{
    className?:string;
}

const Menus:ItemMenuChoixOnPage[] = [
    {id:0, libelle:'Recrutements sur vos métiers'},
    {id:1, libelle:'Autres recrutements'},
    {id:2, libelle:'Nouvelle recherche'},
]

const RecherchesCtrl = (props:RecherchesCtrlProps)=>{
    const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Dynamique de l'effectif", "write", "Recrutements", "Recherches", "")
    const [myMenus, setMyMenus] = useState<ItemMenuChoixOnPage[]>(Menus);
    const [MenuCurrent, setMenuCurrent] = useState<ItemMenuChoixOnPage>(myMenus[0])
    const SeachQuery = useGetAllSearchByMember();
    const listSearchUserMetier = useMemo(() => {
        if (!SeachQuery.data) {
            return [];
        } else {
            return SeachQuery.data.filter((s) => !!s.UserMetier);
        }
    }, [SeachQuery.data]);
    const listSearchMetier = useMemo(() => {
        if (!SeachQuery.data) {
            return [];
        } else {
            return SeachQuery.data.filter((s) => s.Metier);
        }
    }, [SeachQuery.data]);
    useEffect(() => {
        console.log('kk')

        setMyMenus(Menus.filter(m=>isAuthWrite || m.id!==2).map(i=>{
            return i.id === 2 ? i : i.id === 0 ? {...i, libelle:i.libelle+" ("+listSearchUserMetier.length+")"} : {...i, libelle:i.libelle+" ("+listSearchMetier.length+")"}
        }))
    }, [listSearchUserMetier, listSearchMetier, isAuthWrite]);
    useEffect(() => {
        setMenuCurrent(myMenus[0]);
    }, [myMenus]);
    return (
        <div className={`recherches pageStd ${props.className}`}>
            <MbjMenuChoixOnPage current={MenuCurrent} setChoix={setMenuCurrent} Items={myMenus}/>
            {[0,1].indexOf(MenuCurrent.id)!== -1 ?
                <WrapperSearchs list={MenuCurrent.id === 0 ? listSearchUserMetier : listSearchMetier}/>:
                isAuthWrite ?
                <NewRecherche/> : <></>
            }
        </div>
    )
}

const Recherches = styled(RecherchesCtrl)`
    height: 100%;
  display: flex;
  flex-direction: column;
`


export default Recherches;
