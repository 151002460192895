import { useQuery } from "@tanstack/react-query";
import { useGetMemberMe } from "../member";
import { fetchApi } from "../../services/fetchApi";

export const useGetMetiers = () => {
  const promise = () => fetchApi("/api/metiers", { method: "get" });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["metiers"], promise, {});
};

export const useGetMetier = (metierId?: number) => {
  const promise = () => fetchApi("/api/metiers/{id}", { method: "get", params: { id: metierId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["metiers", metierId], promise, {
    enabled: !!metierId,
  });
};

export const useGetMetrixMetier = (metierId?: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () =>
    fetchApi("/api/information_metiers/{id}", {
      method: "get",
      params: { id: metierId + "" },
      query: { Member: memberId || -1 },
    });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["metrix_metier", { metierId, memberId }], promise, {
    enabled: !!memberId || !!metierId,
  });
};

export const useGetMetrixMetierEvaluate = (metierId?: number, particulierID?:number) => {
  const memberQuery = useGetMemberMe();
  let query:any = {};
  if(!particulierID) {
    const memberId = memberQuery.data?.id;
    query = { Member: memberId || -1 };
  } else {
    query = { Particulier: particulierID || -1 };
  }
  const promise = () =>
    fetchApi("/api/information_metiers/{id}", {
      method: "get",
      params: { id: metierId + "" },
      query: query,
    });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["metrix_metier", { metierId }], promise, {
    enabled:  !!metierId,
  });
};


export const useGetMetierByActivity = (activityID: string) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/user_metier_activity/by_competence/{id}", {
    method: "get",
    params: { id: memberId + "" },
    query: { activity: activityID },
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["metiers_activites", { memberId }, activityID], promise, {
    enabled: !!activityID,
  });
};
