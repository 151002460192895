import styled from "styled-components";
import {GetElementType} from "@mbj-front-monorepo/utils";
import {Operation, operations} from "@mbj-front-monorepo/data-access";
import {MbjCardBack, MbjListItem} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface ListeMembreEquipeCtrlProps{
    className?:string;
    Service:GetElementType<Operation<"getServicesCollection">>
}

const ListeMembreEquipeCtrl = (props:ListeMembreEquipeCtrlProps)=>{
    return (
        <MbjCardBack title={"membres"}>
            <div className={`liste_membres ${props.className}`}>
                {props.Service.Employees?.map((employee, index) => (
                    <MbjListItem key={employee.id} to={"../../collaborateurs/" + employee.id}>
                  <span>
                    {employee.nom?.toUpperCase()} {employee.prenom}
                  </span>
                    </MbjListItem>
                ))}
            </div>
        </MbjCardBack>
    )
}

export const ListeMembreEquipe = styled(ListeMembreEquipeCtrl)`
  max-height: 320px;
  overflow-y: auto;
`
