import styled from "styled-components";

interface AnalyseCtrlProps{
    className?:string;
}

const AnalyseCtrl = (props:AnalyseCtrlProps)=>{
    return (
        <div className={`Analyse pageStd ${props.className}`}>
            <h1>Page en cours de construction</h1>
        </div>
    )
}

const Analyse = styled(AnalyseCtrl)``


export default Analyse;
