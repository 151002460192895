import styled from "styled-components";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useGetMemberMe } from "@mbj-front-monorepo/data-access";
import { MbjLoading , MbjCardBack} from "@mbj-front-monorepo/ui";

interface Neo4jCtrlProps{
  className?:string;
}

const Neo4jCtrl = (props:Neo4jCtrlProps)=>{
  const { key } = useParams();
  const type = key === 'metier' ? 'mobilite': 'ages'
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const apiUrl = `https://neo-qxs6ppt6zq-uc.a.run.app/${type}?member__id=${memberId}`
  const fetchData = async (url: string): Promise<string> => {
    try {
      const response = await fetch(url, {
        headers: {
          "Accept": 'text/html',
        }
      });
      return await response.text();
    } catch (error) {
      console.error('Erreur lors de la requête:', error);
      throw error;
    }
  };

  const [htmlContent, setHtmlContent] = useState<string | undefined>(undefined);

  useEffect(() => {
    fetchData(`${apiUrl}`)
      .then((data: string) => {
        const regex = /"response"\s*:\s*"([^"]+)"/;
        const match = data.match(regex);
        const url = match ? match[1] : 'nothing';
        setHtmlContent(url)
      })
      .catch((error: any) =>  setHtmlContent('Erreur lors du chargement !'))
  }, [apiUrl]);
  return (
    <div className={`neo4j ${props.className}`} style={{height: '80vh'}}>
      {htmlContent === undefined && <MbjLoading />}
      {htmlContent !== 'nothing' && htmlContent !== undefined && <iframe style={{border: 0, width: '100%', height: '100%'}}
                                                                         id="visualisationFrame"
                                                                         title="visualisation"
                                                                         width="1100"
                                                                         height="400"
                                                                         src={htmlContent}
      ></iframe>}
      {htmlContent === "nothing" && "Il n'y a pas de résultat pour le moment !"}
    </div>
  )
}

const Neo4j = styled(Neo4jCtrl)`
  padding: 1% 2%;
  .neo4j {
    height: 80vh;
  }
  .r_m, .r_f {
    display: flex;
    justify-content: center;
    align-items: baseline;
    text-align: center;
    font-size: 16px;
    margin-top: .5em;
  }
  .r_m div, .r_f div {
    margin-left: 5px;
  }
  .r_m span, .r_f span {
    display: block;
    width: 20px;
    height: 11px;
  }
  .r_m span {
    background: #0000ff;
  }
  .r_f span {
    background: #008000;
  }
`


export default Neo4j;
