import { Navigate, Route, Routes } from "react-router-dom";
import MetiersDashboard from "./Dashboard/MetiersDashboard";
import React from "react";
import {MetiersListing} from "./Listing/MetiersListing";
import {OneMetierRooter} from "./OneMetier/OneMetierRooter";
import {PrivateRouteSub} from "../../components/PrivateRouteSub";
import {MatrixAllMetiers} from "./Matrix/MatrixAllMetiers";
import { SearchCompetence } from "./SearchCompetence/SearchCompetence";


export function MetiersRooter(){
    return (
        <Routes>
            <Route element={<PrivateRouteSub Onglet={"Métiers"}/>}>
                <Route path="" element={<Navigate to="dashboard" />} />
                <Route path={"dashboard"} element={<MetiersDashboard/>}/>
                <Route path={"listing"} element={<MetiersListing/>}/>
                <Route path={"matrix_metiers"} element={<MatrixAllMetiers/>}/>
                <Route path={":id/*"} element={<OneMetierRooter/>}/>
                <Route path={"search_competence"} element={<SearchCompetence/>}/>
            </Route>
        </Routes>
    )
}
