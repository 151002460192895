import styled, {useTheme} from "styled-components";
import {MbjBlockMenu} from "../../../molecules/MbjMenu/MbjMenuLinks";
import {MbjItemMenuDesktop2} from "../../../atoms/MbjItemMenu/desktop/MbjItemMenuDesktop2";
import * as React from "react";
import {MbjSideDirectLink} from "../../../atoms/MbjItemMenu/desktop/MbjSideDirectLink";
import {GoLaw} from "react-icons/go";
import {useGetMemberMe} from "@mbj-front-monorepo/data-access";
import {useMemo} from "react";


interface MbjSideBarCtrlProps{
    className?:string;
    Blocks:MbjBlockMenu[];
    numVersion:string;
    type?:string;
}

const MbjSideBarCtrl = (props:MbjSideBarCtrlProps)=>{
    const memberQuery = useGetMemberMe();
    const BlockFiltered = useMemo(()=>{
        let myBlocks:MbjBlockMenu[] = [];
        if(props.type === "Particulier"){
          myBlocks = props.Blocks;

        } else if(memberQuery.data){
            const nivoAccess = memberQuery.data.niveauAcces||["-1"];
            const nivoAccessNb = nivoAccess.map(x=>parseInt(x));
            props.Blocks.forEach(b=>{
                if(!b.nivo){
                    myBlocks.push(b);
                } else {
                    let nbCommun = 0;
                    b.nivo.forEach(i=>{
                        if(nivoAccessNb.indexOf(i)!==-1){
                            nbCommun++;
                        }
                    })
                    console.log(b.libelle);
                    console.log(nivoAccessNb);
                    console.log(b.nivo);
                    if(nbCommun>0){
                        myBlocks.push(b);
                    }
                }
            })
        }
        return myBlocks;
    }, [props.Blocks, memberQuery.data, props.type])
    const theme = useTheme();
    return (
        <div className={`mbj_side_bar ${props.className}`}>
            <div className={`logo_place`}>
                <img src={theme.logo} alt="logo mbj" className={"logo"} />
            </div>
            <div className={`wrap_menus`}>
                {memberQuery.isLoading ? '...':
                    BlockFiltered.map((block, idx)=>(
                        <MbjItemMenuDesktop2 Block={block} key={`block${idx}`}/>
                    ))
                }
                <MbjSideDirectLink to={"mentions"} libelle={"Mentions légales"} icon={<GoLaw />}/>
            </div>
            <div className={`version`}>
                {props.numVersion}
            </div>
        </div>
    )
}

export const MbjSideBar = styled(MbjSideBarCtrl)`
  width: 230px;
  background: ${props=>props.theme.dark};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .version{
    padding: 1rem;
    text-align: center;
    color: white;
  }
  .wrap_menus{
    margin: 15px 0;
    flex-grow: 1;
    overflow-y: auto;
  }
  .logo_place{
    padding: 1rem 2rem;
    img{
      width: 100%;
    }
  }
`
