import styled from "styled-components";
import {MbjCardBack, MbjButton} from "@mbj-front-monorepo/ui";
import * as React from "react";
import { NavLink } from "react-router-dom";
interface ObjectifSectionMetierCtrlProps {
  className?: string
}

const ObjectifSectionMetierCtrl = (props: ObjectifSectionMetierCtrlProps) => {
  return (
    <MbjCardBack title={"Objectifs de la section Métier"}>
      <div className={`obj ${props.className}`}>
        <p>Dans cette section, décrivez les fiches de postes des différents métiers de l’entreprise. L’onglet « Tableau
          de
          bord » présente les principaux indicateurs de cette rubrique : nombre de métiers décrits, liste, répartition
          par
          grande fonction.
        </p>
        <br />
        <p>Dans l’onglet « Métiers de l’entreprise », vous pouvez, grâce aux deux boutons d’ajout, créer des
          référentiels
          sur-mesure décrivant vos métiers. Cette étape est importante : ces référentiels structurent ensuite
          l’évaluation
          des compétences des collaborateurs, l’analyse des mobilités internes, les recherches de collaborateurs…</p>
        <br />
        <p>Le bouton « Ajouter un métier » affiche la liste des métiers déjà décrits dans My-Better-Job, parmi laquelle
          vous pouvez sélectionner un ou plusieurs métiers pour importer leur référentiel de compétences et les adapter
          à
          vos besoins. Le bouton « Scanner une fiche de poste » permet l’analyse par l’intelligence artificielle de
          My-Better-Job des fichiers pdf ou docx décrivant des fiches existantes. La transcription dans la nomenclature
          de
          compétences du site est automatisée. Pensez toutefois à vérifier le résultat en cliquant sur le libellé du
          métier ajouté (en bas du tableau) puis sur le menu « Référentiel Métier ».</p>
        <br />
        <p>Les menus « Evolution Métier » et « Marché du travail » présentent les analyses de l’équipe My-Better-Job sur
          les transformations en cours dans le métier étudié ainsi que sur sa situation sur le marché du travail.</p>
        <br />
        <p>Le tableau affichant les métiers de l’entreprise présente des informations calculées à partir des
          descriptions
          des collaborateurs, si vous les décrivez dans My-Better-Job.</p>
      </div>
      <br />
      <NavLink to="/entreprise/entreprise/neo4j/metier" style={{textDecoration: 'none', color: '#fff'}}>
        <MbjButton>Carrière dans l'entreprise</MbjButton>
      </NavLink>
    </MbjCardBack>
  )
}

export const ObjectifSectionMetier = styled(ObjectifSectionMetierCtrl)`
    `
