import styled from "styled-components";
import {useMemo, useRef, useState} from "react";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {useGetMapInfosAll} from "@mbj-front-monorepo/data-access";
import {FrameCarte} from "./FrameCarte";

interface ChiffresClesCtrlProps{
    className?:string;
}

const ChiffresClesCtrl = (props:ChiffresClesCtrlProps)=>{
  const MapInfosQuery = useGetMapInfosAll();
  const refSelect = useRef<HTMLSelectElement>(null);
  const [MyMetierChoose, setMyMetierChoose] = useState<{id:number, libelle:string, htmlContent:string}|null>(null)
  const [MyMetierChooseR, setMyMetierChooseR] = useState<{id:number, libelle:string, htmlContent:string}|null>(null)
  const [MyType, setMyType] = useState<string>('N');
  const myListChoice = useMemo(()=>{
    const list:{id:number, libelle:string, htmlContent:string}[] = []
    if(MapInfosQuery.data){
      const myUse:string[] = [];
      MapInfosQuery.data.forEach((item)=>{
        if(myUse.indexOf((item.Metier?.libelle||''))===-1) {
          list.push({id: (item.Metier?.id || 0), libelle: (item.Metier?.libelle || ''), htmlContent: (item.contentHtml || '')})
          myUse.push((item.Metier?.libelle||''))
        }
      })
    }
    return list;
  }, [MapInfosQuery.data])
  const handleChange = ()=>{
    if (refSelect.current && refSelect.current.value) {
      const selectedMetiers = MapInfosQuery.data?.filter(i=>{
        return (i.Metier?.id||0)===parseInt(refSelect.current!.value);
      })
      if(selectedMetiers && selectedMetiers.length>0){
        const mySelectedN = selectedMetiers.find(s=>s.typeFile === 'N');
        const mySelectedR = selectedMetiers.find(s=>s.typeFile === 'R');
        if(mySelectedN){
          setMyMetierChoose({id:(mySelectedN.id||0), libelle:(mySelectedN.Metier?.libelle||''), htmlContent:(mySelectedN.contentHtml||'')})
        }
        if(mySelectedR){
          setMyMetierChooseR({id:(mySelectedR.id||0), libelle:(mySelectedR.Metier?.libelle||''), htmlContent:(mySelectedR.contentHtml||'')})
        }
      }

    }
  }
    return (
        <div className={`chiffres_cles pageStd ${props.className}`}>
          {MapInfosQuery.isLoading ? <MbjLoading/>:
            <>
              <div className="select_wrapper">
                <select ref={refSelect} value={MyMetierChoose ? MyMetierChoose.id : 0} onChange={handleChange}>
                  <option value={0}>Choix du Metier</option>
                  {myListChoice.map((item) => (
                    <option key={`Choice${(item.id || 0)}`} value={item.id}>{item.libelle}</option>
                  ))}
                </select>
              </div>

              <div className={"contente_card"}>
                {(!MyMetierChoose || !MyMetierChooseR) ?
                  <p className={`no_choice`}>Afin de pouvoir visualiser la carte merci de sélectionner un métier</p> :
                  <>
                    <div className="selectType">
                      <div className={`oneChoose ${MyType === 'N' && 'current'}`}
                           onClick={() => setMyType('N')}>Répartition par zone d'emploi
                      </div>
                      <div className={`oneChoose ${MyType === 'R' && 'current'}`}
                           onClick={() => setMyType('R')}>Répartition Régionale
                      </div>
                    </div>
                    <FrameCarte
                      htmlFileString={MyType === 'N' ? MyMetierChoose.htmlContent : MyMetierChooseR.htmlContent}/>
                  </>
                }

              </div>
              <div className={`source`}>
                <p>Sources :</p>
                <p>Nous collectons les données d'effectif et insertion auprès des services suivants :</p>
                <ul style={{margin:"15px 0 0 20px"}}>
                  <li>candidat.pole-emploi.fr</li>
                  <li>travail-emploi.gouv.fr</li>
                  <li>Parcours sup</li>
                  <li>Enseignement sup</li>
                  <li>Formation pole emploi</li>
                  <li>Inserjeunes</li>
                  <li>Cfa emploi</li>
                  <li>data.gouv</li>
                  <li>cereq</li>
                </ul>
              </div>
            </>
          }
        </div>
    )
}

const ChiffresCles = styled(ChiffresClesCtrl)`
  .no_choice {
    margin: 20px 0;
    font-size: 16px;
  }
    .source{
      padding: 2%;
    }
  .select_wrapper {
    select {
      height: 36px;
      border-radius: 4px;
    }
  }

  .selectType {
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    gap: 10px;

    .oneChoose {
      &:hover {
        cursor: pointer;
      }

      color: ${props => props.theme.primaryDarken};

      &.current {
        border-bottom: solid 3px ${props => props.theme.primaryDarken};
        font-weight: bold;
      }
    }
  }
`


export default ChiffresCles;
