import styled from "styled-components";
import {
    createSelectColumn,
    MbjButton, MbjLink,
    MbjLoading,
    MbjScrollArea,
    MbjTableV2,
    MbjWidgetClassic, useModal
} from "@mbj-front-monorepo/ui";
import {GoPlus} from "react-icons/go";
import * as React from "react";
import {
    useDeleteUserMetier2,
    useDuplicateUserMetier, useGetRight,
    useGetUserMetierStatistiques
} from "@mbj-front-monorepo/data-access";
import {
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {getDateFR, GetElementType} from "@mbj-front-monorepo/utils";
import {AjoutMetierModal} from "../AjoutMetierModal/AjoutMetierModal";
import {AjoutMetierByScanModal} from "../AjoutMetierModal/AjoutMetierByScanModal";


interface MetiersListingCtrlProps{
    className?:string;
}

const MetiersListingCtrl = (props:MetiersListingCtrlProps)=>{
    const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Métiers", "write", "MetierEntreprise")
    const {isLoading:loadRightR, isAuth:isAuthRead} = useGetRight("Métiers", "read", "MetierEntreprise", "OneMetier")
    const mutationDuplicate = useDuplicateUserMetier();
    const userMetierSatistiquesQuery = useGetUserMetierStatistiques();
    const { isShowing: ajoutIsShowing, toggle: ajoutToggle } = useModal();
    const { isShowing: isShowingFDP, toggle: toggleFDP } = useModal();
    const mutationTrash = useDeleteUserMetier2();
    const columnHelper = createColumnHelper<GetElementType<typeof userMetierSatistiquesQuery.data>>();
    const columns = [
        createSelectColumn(columnHelper),
        columnHelper.accessor((row) => row.UserMetier?.namePerso || "--", {
            header: "Nom",
            cell: (info) => isAuthRead ? <MbjLink to={'../'+info.row.original.UserMetier?.id?.toString() || "0"}>{info.getValue()}</MbjLink> : <span>{info.getValue()}</span>,
        }),
        columnHelper.accessor((row) => row.effectif, {
            header: "Effectif",
        }),
        columnHelper.accessor((row) => row.older, {
            header: "Le plus ancien",
            cell: (info) => {
                const value = info.getValue();
                return value ? getDateFR(new Date(value)) : "--";
            },
        }),
        columnHelper.accessor((row) => row.younger, {
            header: "Le plus récent",
            cell: (info) => {
                const value = info.getValue();
                return value ? getDateFR(new Date(value)) : "--";
            },
        }),
        columnHelper.accessor((row) => row.ageMedian, {
            header: "Age médian",
            cell: (info) => info.getValue() || "--",
        }),
        columnHelper.accessor((row) => row.avgSalary, {
            header: "Salaire moyen",
            cell: (info) => info.getValue() || "--",
        }),
    ];
    const table = useReactTable({
        data: userMetierSatistiquesQuery?.data ?? [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        initialState: {
            sorting: [{ id: "Effectif", desc: true }],
        },
        debugTable: false,
    });
    const DuplicateMetier = ()=>{
        const lineSelected = table.getSelectedRowModel().rows[0];
        if(lineSelected){
            const UserMetier = lineSelected.original.UserMetier;
            if(UserMetier) {
                mutationDuplicate.mutate({UserMetier: {id: UserMetier.id}})
            }
        }
    }
    const TrashMetier = ()=>{
        const lineSelected = table.getSelectedRowModel().rows[0];
        if(lineSelected){
            const UserMetier = lineSelected.original.UserMetier;
            if(UserMetier) {
                mutationTrash.mutate(UserMetier.id)
            }
        }
    }

    const CanDelete = ()=>{
        const lineSelected = table.getSelectedRowModel().rows[0];
        if(lineSelected  && isAuthWrite ){
            console.log('yes')
            const Effectif = lineSelected.original.effectif;
            console.log(Effectif);
            if(Effectif === 0){
                console.log('ok');
                return true;
            }
            return false;
        }
        return false;
    }
    return (
        <div className={`Metier_listing pageStd ${props.className}`}>
            {userMetierSatistiquesQuery.isLoading ? (
                <MbjLoading />
            ) : userMetierSatistiquesQuery.isError ? (
                <p>Error: {userMetierSatistiquesQuery.error.message}</p>
            ) : (
                <>
                    <div className={"space-between"}>
                        <div className={"ResultNumber"}>{table.getRowModel().rows.length} résultats</div>
                        <div style={{display:"flex", justifyContent:"flex-end", gap:"10px"}}>
                            {(table.getIsSomeRowsSelected() && table.getSelectedRowModel().rows.length === 1 && isAuthWrite) && (
                                <MbjButton themeColor={"warning"} onClick={DuplicateMetier} isPending={false}>
                                    Dupliquer le métier
                                </MbjButton>
                            )}
                            {CanDelete() &&
                                <MbjButton themeColor={"warning"} onClick={TrashMetier} isPending={false}>
                                    Supprimer le métier
                                </MbjButton>
                            }
                            {isAuthWrite &&
                                <>
                                <MbjButton onClick={ajoutToggle} leftIcon={<GoPlus />}>
                                    Ajouter un métier
                                </MbjButton>
                                <MbjButton onClick={toggleFDP} leftIcon={<GoPlus />} alignSelf={"flex-end"}>
                                    Scanner une fiche de poste
                                </MbjButton>
                                </>
                            }
                        </div>
                    </div>

                    <MbjScrollArea overflowX={"auto"}>
                        <MbjTableV2 table={table} Multiple={true}/>
                    </MbjScrollArea>
                </>
            )}
          <AjoutMetierModal isShowing={ajoutIsShowing} hide={ajoutToggle} />
          <AjoutMetierByScanModal isShowing={isShowingFDP} hide={toggleFDP}/>
        </div>
    )
}

export const MetiersListing = styled(MetiersListingCtrl)`
    display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  .space-between{
    display:flex;
    justify-content:space-between;
    align-items: center;
  }
  .ResultNumber{
    font-size: 20px;
  }
`
