import styled from "styled-components";
import {
    AvatarAnonyme,
    AvatarEmp,
    MbjButton,
    MbjCardBack,
    MbjFlex,
    MbjLoading, MbjModal2,
    MbjStaticInfo, useModal
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {useParams} from "react-router-dom";
import {
  useGetAllListEquipeManager,
  useGetAllListEquipeManagerByEmployee,
  useGetEmployee,
  useGetMemberMe, useTrashMemberEquipeManager,
} from "@mbj-front-monorepo/data-access";
import {getDateFR} from "@mbj-front-monorepo/utils";
import { BsPencil, BsPersonPlus } from "react-icons/bs";
import {MbjEmployeeEditInformationsCard} from "@mbj-front-monorepo/data-ui";
import { ModalSendOneCollaborateurToEquipe } from "./ModalSendOneCollaborateurToEquipe";

interface OneCollaborateurPrezCtrlProps{
    className?:string;
    readonly:boolean;
}

const OneCollaborateurPrezCtrl = (props:OneCollaborateurPrezCtrlProps)=>{
    const { id } = useParams();
    const employeeQuery = useGetEmployee(parseInt(id || "-1"));
    const memberQuery = useGetMemberMe();
    const equipes = useGetAllListEquipeManager()
    const deleteMemberTeam = useTrashMemberEquipeManager()
    const {isShowing:show, toggle} = useModal();
    const {isShowing:showModalTeam, toggle: toggleModalTeam} = useModal();
    const teamListQuery = useGetAllListEquipeManagerByEmployee(parseInt(id || "-1"))
    return (
        <MbjCardBack>
            <div className={`wrap_prez ${props.className}`}>
                <div className={`avatar_place part_of`}>
                    <div className={"Avatar_place"}><img src={AvatarEmp} alt={"avatar_part"}/></div>
                </div>
                <div className={`infos_wrapper`}>
                    {employeeQuery.isLoading ? (
                        <MbjLoading />
                    ) : employeeQuery.error ? (
                        <p>Erreur ...</p>
                    ) : (
                        <div>
                            <div className={"line_infos flexy"}>
                                <MbjStaticInfo label="Prénom et Nom" info={`${employeeQuery.data.prenom} ${employeeQuery.data.nom}`} />
                                <MbjStaticInfo
                                    label="adresse"
                                    info={employeeQuery.data.adresse ? `${employeeQuery.data.adresse} ${employeeQuery.data.codePostal} ${employeeQuery.data.ville}` : "inconnue"}
                                />
                            </div>
                            <div className={"line_infos"}>

                            </div>
                            <div className={`line_infos flexy`}>
                                <MbjStaticInfo label="Email" info={employeeQuery.data.email ? employeeQuery.data.email : "inconnu"} />
                                <MbjStaticInfo
                                    label="Tel1"
                                    info={employeeQuery.data.telephone1 ? employeeQuery.data.telephone1 : "inconnu"}
                                />
                                <MbjStaticInfo
                                    label="Tel2"
                                    info={employeeQuery.data.telephone2 ? employeeQuery.data.telephone2 : "inconnu"}
                                />
                            </div>
                            <div className={`line_infos`}>
                                <MbjStaticInfo
                                    label="Date d'intégration"
                                    info={employeeQuery.data.birthAt ? getDateFR(new Date(employeeQuery.data.birthAt)) : "inconnue"}
                                />
                            </div>
                            <div className={`line_infos flexy`}>
                                <MbjStaticInfo
                                    label="Poste occupé"
                                    info={`${employeeQuery.data.UserMetier ? employeeQuery.data.UserMetier.namePerso : "inconnu"}`}
                                />
                                <MbjStaticInfo
                                    label="Service"
                                    info={`${employeeQuery.data.Service ? employeeQuery.data.Service.libelle : "inconnu"}`}
                                />
                                <MbjStaticInfo
                                    label="Depuis"
                                    info={`${employeeQuery.data.entryAt ? getDateFR(new Date(employeeQuery.data.entryAt)) : "inconnu"}`}
                                />
                            </div>
                            {!props.readonly &&
                                <div className={"wrap_action"} style={{marginTop:"15px"}}>
                                    <MbjButton size={"xs"} themeColor={'third'} onClick={toggle}>
                                        <BsPencil/><span style={{marginLeft:"6px"}}>Modifier les informations</span>
                                    </MbjButton>
                                    {employeeQuery?.data?.id  && <div>
                                      <MbjButton size={"xs"} themeColor={"primary"} onClick={toggleModalTeam}>
                                        <BsPersonPlus /><span style={{ marginLeft: "6px" }}>Ajouter à une équipe</span>
                                      </MbjButton>
                                    </div>}
                                </div>
                            }
                          {
                            teamListQuery.data?.length > 0 && <div className="blk_team">
                              <p className="label-infos">Liste des équipes</p>
                              <ul className="liste-team">
                                {teamListQuery.data?.map((equipe: any, index: number) => (
                                  <li key={index} className="item-blk">
                                    <span className="tm-libelle">{equipe.nom}</span>
                                    <span className="rm-team" onClick={() => deleteMemberTeam.mutate(equipe.id)}>x</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          }
                        </div>
                    )}
                </div>
            </div>
            <MbjModal2 open={show} closeMe={toggle} titre={"Modification des informations"} Wd={"65%"}>
                <MbjEmployeeEditInformationsCard employeeId={id ? parseInt(id) : -1} memberId={memberQuery.data?.id} newStyle={true} />
            </MbjModal2>
          {employeeQuery?.data?.id && <ModalSendOneCollaborateurToEquipe isShowing={showModalTeam} hide={toggleModalTeam} employee={employeeQuery.data} />}
        </MbjCardBack>
    )
}

export const OneCollaborateurPrez = styled(OneCollaborateurPrezCtrl)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  .wrap_action {
    display: flex;
    justify-content: space-between;
  }
  .item-blk{
    display: flex;
  }
  .label-infos {
    font-size: 15px;
    font-weight: bold;
    color: #69979d
  }
  .liste-team {
    list-style-type: none;
  }
  .tm-libelle {
    display: block;
  }
  .rm-team {
    color: #fff;
    border-radius: 50%;
    display: block;
    width: 13px;
    height: 13px;
    font-size: 12px;
    line-height: 12px;
    background: #d21b1b;
    text-align: center;
    margin-top: 3px;
    margin-left: 10px;
  }
  .rm-team:hover {
    cursor: pointer;
  }
  .blk_team {
    margin-top: 20px
  }
  .line_infos{
    margin-bottom: 8px;
    &.flexy{
      display: flex;
      justify-content: flex-start;
      gap: 18px;
    }
  }
  .avatar_place{
    width: 150px;
  }
  .Avatar_place{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    img{
      margin: auto;
      width: 60%;
    }

  }
`
