import styled from "styled-components";
import {
  useGetAllListEquipeManager,
  useGetRight, useTrashEquipeManager,
} from "@mbj-front-monorepo/data-access";
import {
  createSelectColumn,
  MbjButton, MbjConfirmModal,
  MbjIconButton,
  MbjLoading, MbjModal2,
  MbjScrollArea, MbjTableV2,
  useModal,
} from "@mbj-front-monorepo/ui";
import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"
import * as React from "react";
import { BiBullseye, BiCalendar } from "react-icons/bi";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { GoPlus } from "react-icons/go";
import { ModalAddEquipe } from "./ModalAddEquipe";
import { useState } from "react";
import { DetailsEquipe } from "./DetailsEquipe";

interface EntrepriseEquipesCtrlProps{
    className?:string;
}


const EntrepriseEquipesCtrl = (props:EntrepriseEquipesCtrlProps)=>{
    const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Entreprise", "write", "Equipes", "listing")
    const equipes = useGetAllListEquipeManager()
    const {isShowing, toggle} = useModal();
    const [teamID, setTeamID] = useState(0)
    const [teamUserID, setTeamUserID] = useState(0)
    const {isShowing: isShowingInfo, toggle: toggleInfo} = useModal();
    const {isShowing: isShowingDeleteTeam, toggle: toggleDeleteTeam} = useModal();
    const columnHelper = createColumnHelper<GetElementType<typeof equipes.data>>();
    const formatDate = (d: string) => {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }
    const deleteEquipe = useTrashEquipeManager()
    const handleView = (row: any) => {
      toggleInfo()
      setTeamID(row.id)
      setTeamUserID(row.user_id)
    }
    const columns = [
      createSelectColumn(columnHelper),
      columnHelper.accessor((row: any) => row.nom, {
        header: "Equipe",
      }),
      columnHelper.accessor((row: any) => formatDate(row.creationAt), {
        header: "Date de création",
      }),
      columnHelper.display({
        header: 'Actions',
        id: 'actions',
        cell: (props: any) => <div className="btn-actions">
          <MbjIconButton
            size={"sm"}
            ariaLabel={"Membres"}
            icon={<BiBullseye />}
            themeColor={"primary"}
            isRound={false}
            onClick={() => handleView(props.row?.original)}
          />
        </div>,
      }),
    ];
    const table = useReactTable({
    data: equipes?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    enableRowSelection: true,
  })



  return (
    <div className={`Priorisation_listing pageStd ${props.className}`}>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
        <MbjButton onClick={toggle} leftIcon={<GoPlus />}>
          Ajouter une équipe
        </MbjButton>
      </div>
      {equipes.isLoading ? (
        <MbjLoading />
      ) : equipes.isError ? (
        <p>Erreur</p>
      ) : (
        <>
          <div className={"space-between"}>
            <div
              className={"ResultNumber"}>{table.getRowModel().rows.length > 1 ? `${table.getRowModel().rows.length} résultats` : `${table.getRowModel().rows.length} résultat`}</div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {((table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && isAuthWrite) && (
                <>
                  <MbjButton themeColor={"warning"} isPending={false} onClick={toggleDeleteTeam}>
                    Retirer {table.getSelectedRowModel().rows.length} équipe(s)
                  </MbjButton>
                </>
              )}
            </div>
          </div>
          <MbjScrollArea overflowX={"auto"}>
            <MbjTableV2 table={table} Multiple={true} />
          </MbjScrollArea>
        </>
      )}
      <ModalAddEquipe isShowing={isShowing} hide={toggle} />
      <MbjModal2 open={isShowingInfo} closeMe={toggleInfo} titre={"Informations détaillés"} Wd={"80%"}>
        <div style={{padding:"0 1rem", height:"max-content"}}>
          <DetailsEquipe teamId={teamID} teamUserId={teamUserID}/>
        </div>
      </MbjModal2>
      <MbjConfirmModal
        isShowing={isShowingDeleteTeam}
        hide={toggleDeleteTeam}
        title="Confirmation de suppression d'équipe(s)"
        confirmText={`Etes-vous sûr de vouloir retirer ${table.getSelectedRowModel().rows.length} équipe(s) ?`}
        confirmFunction={() => {
          table.getSelectedRowModel().rows.map((row: any) => {
            return deleteEquipe.mutate(row.original.id,
              {
                onSuccess: () => {
                  toggleDeleteTeam();
                  table.toggleAllRowsSelected(false);
                },
              }
            );
          })
        }}
        isLoading={deleteEquipe.isLoading}
      />
    </div>

  )
}

const EntrepriseEquipes = styled(EntrepriseEquipesCtrl)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 50px;

  .space-between {
    display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ResultNumber {
      font-size: 20px;
    }

    .btn-actions {
      display: flex;
      justify-content: space-between;
    }

    .btn-actions button:first-child {
      margin-right: 10px;
    }

    .blk-plan {
      margin: 10px 0;
    }

    .plprg {
      margin-bottom: 0.5rem;
    }

    .plprg_select {
      display: block;
      margin: 20px 10px 20px 0;
    }
  `
;


export default EntrepriseEquipes;
