import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    MbjButton,
    MbjConfirmModal,
    MbjFlex,
    MbjHeading,
    MbjLoading,
    MbjNavPath,
    MbjTable,
    MbjWidgetClassic,
    useModal,
} from "@mbj-front-monorepo/ui";
import {useGetEmployee, useGetEntretienAnnuels, useGetRight} from "@mbj-front-monorepo/data-access";
import { GoPlus } from "react-icons/go";

interface EntretienPeriodiqueCtrlProps {
    className?: string;
}

const titleTab = [
    { libelle: "Année" },
    { libelle: "Signé par l'employé(e) le" },
    { libelle: "Signé par le responsable le" },
    { libelle: "Signé par la direction le" },
    { libelle: "Professionnel/Annuel" },
    { libelle: "téléchargement" },
];

const EntretienPeriodiqueCtrl = ({ className }: EntretienPeriodiqueCtrlProps) => {
    const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Collaborateurs", "write", "Mes collaborateurs", "OneCollab", "entretien")
    const navigate = useNavigate();
    const location = useLocation();

    const { id } = useParams();
    const employeeQuery = useGetEmployee(parseInt(id || "-1"));
    const { isLoading, isError, data: entretiens } = useGetEntretienAnnuels(parseInt(id || "-1"));
    const [hasEntretienThisYear, setHasEntretienThisYear] = useState(false);
    const { isShowing, toggle } = useModal();
    useEffect(() => {
        if (entretiens) {
            const thisyear = new Date().getFullYear();
            const EntretienThisYear = entretiens.find((ea) => ea.annee === thisyear);
            if (EntretienThisYear !== undefined && EntretienThisYear !== null) {
                setHasEntretienThisYear(true);
            }
        }
    }, [entretiens]);
    const handleCallBack = () => {
        navigate("new");
    };
    const handleClickNew = () => {
      navigate("new");
    };
    const DownloadEntretien = (ea:any)=>{
      window.open(`${process.env["REACT_APP_API_URL_ROOT"]}/Entretiens/${ea.document}`)
    }

    const mots = ["professionnel", "annuel"];
    return (
        <div className={"Suivi " + className}>
            {employeeQuery.isLoading ? (
                <MbjLoading />
            ) : employeeQuery.isError ? (
                <p>Erreur ...</p>
            ) : (
                <>
                    <div style={{display:"flex", padding:"0.5rem 0", justifyContent:"flex-end"}}>
                        {isAuthWrite &&
                            <MbjButton onClick={handleClickNew} themeColor={"third"} disabled={isLoading} leftIcon={<GoPlus />}>
                                Nouvel entretien
                            </MbjButton>
                        }
                    </div>
                    <MbjFlex direction={"column"}>
                        <MbjWidgetClassic title="Liste des entretiens">
                            <MbjTable titles={titleTab} scrollable maxHeight={"300px"}>
                                {isLoading ? (
                                    <tr>
                                        <td colSpan={titleTab.length}>
                                            <MbjLoading />
                                        </td>
                                    </tr>
                                ) : (
                                    entretiens?.map((ea, idx) => (
                                      <tr key={ea.id}>
                                        <td className="isCenter">{ea.annee}</td>
                                        <td className="isCenter">
                                          {ea.signEmpAt ? new Date(ea.signEmpAt).toLocaleDateString() : "non signé"}
                                        </td>
                                        <td className="isCenter">
                                          {ea.signRespAt ? new Date(ea.signRespAt).toLocaleDateString() : "non signé"}
                                        </td>
                                        <td className="isCenter">
                                          {ea.signDirectionAt ? new Date(ea.signDirectionAt).toLocaleDateString() : "non signé"}
                                        </td>
                                        <td className="isCenter">
                                          {ea.signDirectionAt ? new Date(ea.signDirectionAt).toLocaleDateString() : "non signé"}
                                        </td>
                                        <td className="isCenter">{mots[Math.floor(Math.random() * mots.length)]}</td>
                                        <td>
                                          <div style={{ margin: "auto", display: "flex", justifyContent: "center" }}>
                                            <MbjButton size={"sm"} themeColor={"secondary"}
                                                       onClick={() => DownloadEntretien(ea)}>Télécharger</MbjButton>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                )}
                              {!isLoading && entretiens?.length === 0 && (
                                <tr>
                                <td colSpan={titleTab.length}>Aucun entretien pour le moment</td>
                                    </tr>
                                )}
                            </MbjTable>
                        </MbjWidgetClassic>
                    </MbjFlex>
                </>
            )}
            <MbjConfirmModal
                isShowing={isShowing}
                hide={toggle}
                title="Entretien périodique déjà existant"
                confirmText={
                    "Attention, il existe déjà un entretien pour cette année, si vous poursuivez vous écraserez l'existant. Voulez-vous continuez "
                }
                confirmFunction={handleCallBack}
            />
        </div>
    );
};

export const EntretienPeriodique = styled(EntretienPeriodiqueCtrl)((props) => ({
    padding: "2%",
    ".top": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "15px",

        ".right": {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
        },
    },
}));
