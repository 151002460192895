import styled from "styled-components";
import { MbjLoading, MbjWidgetClassic } from "@mbj-front-monorepo/ui";
import * as React from "react";
import {
  MbjCardBack,
} from "@mbj-front-monorepo/ui";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

interface OneCollaborateurCarriereInterneCtrlProps{
  className?:string;
}


const OneCollaborateurCarriereInterneCtrl = (props:OneCollaborateurCarriereInterneCtrlProps)=>{
  const { id } = useParams();
  const apiUrl = `https://neo-qxs6ppt6zq-uc.a.run.app/carriere?employees__id=${id}`
  const fetchData = async (url: string): Promise<string> => {
    try {
      const response = await fetch(url, {
        headers: {
          "Accept": 'text/html',
        }
      });
      return await response.text();
    } catch (error) {
      console.error('Erreur lors de la requête:', error);
      throw error;
    }
  };

  const [htmlContent, setHtmlContent] = useState<string | undefined>(undefined);

  useEffect(() => {
    fetchData(`${apiUrl}`)
      .then((data: string) => {
        const regex = /"response"\s*:\s*"([^"]+)"/;
        const match = data.match(regex);
        const url = match ? match[1] : 'nothing';
        setHtmlContent(url)
      })
      .catch((error: any) =>  setHtmlContent('Erreur lors du chargement !'))
  }, [apiUrl]);

  return (
    <div className={`mob_ext ${props.className}`}>
      <MbjWidgetClassic bodyDirection={"row"} gap={"10px"}>
        <MbjWidgetClassic basis={"calc(100% - 20px)"}>
          <MbjCardBack title={"Carrière interne"}>
            <div className={`neo4j ${props.className}`} style={{height: '80vh'}}>
              {htmlContent === undefined && <MbjLoading />}
              {htmlContent !== 'nothing' && htmlContent !== undefined &&
                <iframe style={{ border: 0, width: '100%', height: '100%' }}
                        id="visualisationFrame"
                        title="visualisation"
                        width="1100"
                        height="400"
                        src={htmlContent}
                ></iframe>}
              {htmlContent === 'nothing' && 'Il n\'y a pas de résultat pour le moment !'}
            </div>
          </MbjCardBack>
        </MbjWidgetClassic>
      </MbjWidgetClassic>
    </div>
  )
}

export const OneCollaborateurCarriereInterne = styled(OneCollaborateurCarriereInterneCtrl)``
