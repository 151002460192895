import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { fetchApi } from "../../services/fetchApi";
import {toast} from "react-toastify";
import {useGetMemberMe} from "../member";

export const useGetAlliance = (allianceId: number) => {
  const promise = () => fetchApi("/api/alliances/{id}", { method: "get", params: { id: allianceId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["alliances", { allianceId }], promise, {
    enabled: !!allianceId,
  });
};

export const useGetStats = (allianceId: number) => {
  const promise = () => fetchApi("/api/alliance_stats/{id}", { method: "get", params: { id: allianceId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["alliances_stats", { allianceId }], promise, {
    enabled: !!allianceId,
  });
};

export const useGetAllianceNeedFormation = (allianceId: number) => {
  const promise = () => fetchApi("/api/alliance_need_formations", { method: "get", query: { Alliance: allianceId } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["alliances_need_formations", { allianceId }],
    promise,
    {}
  );
};

export const useGetAllianceMembers = (allianceId: number) => {
  const promise = () => fetchApi("/api/alliance_members", { method: "get", query: { Alliance: allianceId + "" } });
  return useQuery(["alliance_members", { allianceId }], promise, {
    enabled: !!allianceId && allianceId!==0,
  });
};

export const useAddAlliance = ()=>{
  const queryClient = useQueryClient();
  return useMutation( (name:string)=>{
    return fetchApi("/api/alliances", {
      method: "post",
      json: {name:name},
    });
  },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["alliance_members"]);
          queryClient.invalidateQueries(["alliances"]);
          queryClient.invalidateQueries(["member"]);
          toast.success("alliance ajoutée !", {});
        },
        onError: (error: any) => {
          toast.error("Erreur : " + error.message, {});
        },
      }
  );
};

export const useGetPutInactiveMember = ()=>{
  const queryClient = useQueryClient();
  return useMutation( (datas: {
        isActive: boolean,
        id:number
      })=>{
        return fetchApi("/api/alliance_members/{id}", {
          method: "put",
          params:{id:datas.id+""},
          json: {isActive:datas.isActive},
        });
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["alliance_members"]);
          queryClient.invalidateQueries(["alliances"]);
          queryClient.invalidateQueries(["member"]);
          toast.success("Membre modifié !", {});
        },
        onError: (error: any) => {
          toast.error("Erreur : " + error.message, {});
        },
      }
  );
}

export const useGetAllianceInvitationsSended = (id:number) => {
  const promise = () => fetchApi("/api/alliance_invitations", { method: "get", query: { Alliance: id + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["alliance_invitation_sended", { id }], promise, {
    enabled: id!==0,
  });
};
