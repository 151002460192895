import * as React from "react";

import {
  MbjCardClassic,
  MbjFlex,
  MbjLoading,
  MbjWidgetClassic,
  MbjTable, MbjButton,
} from "@mbj-front-monorepo/ui";


import styled from "styled-components";
import {
  useFindMatchingAllianceMembersForEmployee,
  useGetTopRecrutementByEmployee,
} from "@mbj-front-monorepo/data-access";
import { TopRecrutementUserMetier } from "./TopRecrutementUserMetier";
import { MatchingEmployee } from "./MatchingEmployee";
import { useState } from "react";
import { TopRecrutementDetailEmployee } from "./TopRecrutementDetailEmployee";

interface TopRecrutementEmployeeCtrlProps {
  className?: string;
  id:number|undefined;
}

const TopMetierTitles = [
  { libelle: "Zone d'emploi", Align: "isCenter" },
  { libelle: "Metier", Align: "isCenter" },
  { libelle: "Raison sociale", Align: "isCenter" },
  { libelle: "Distance (en km)", Align: "isCenter" },
];

const MatchEmployeeTitles = [
  { libelle: "Entreprise", Align: "isCenter" },
  { libelle: "Metier", Align: "isCenter" },
  { libelle: "Score (en %)", Align: "isCenter" },
];

function TopRecrutementEmployeeCtrl(props: TopRecrutementEmployeeCtrlProps) {
  const topRecrutements = useGetTopRecrutementByEmployee(props.id)
  const matchingsMembersEmployee = useFindMatchingAllianceMembersForEmployee(props.id)



  return (
    <div className={"NouveauReclassement" + props.className}>
      <MbjFlex direction={"column"}>

        <MbjWidgetClassic title={"Statistiques du reclassement pour ce profil"}>
          <MbjCardClassic title="Les recruteurs locaux pour ce profil" basis={"100%"}>
            {topRecrutements.isLoading ? <MbjLoading /> : topRecrutements.isSuccess && topRecrutements.data?.length > 0 ? <>
              <MbjTable titles={TopMetierTitles} themeColor={"primary"} noBorderRadius>
                {topRecrutements.data?.map((item: any) => <TopRecrutementDetailEmployee key={item.id} item={item} />)}
              </MbjTable>
              </> : <p>Aucun résultat</p>}

          </MbjCardClassic>
          <MbjCardClassic title="Proximité vers les métiers d'autres entreprises de l'alliance" basis={"100%"}>
            {matchingsMembersEmployee.isLoading ? <MbjLoading /> : matchingsMembersEmployee?.data?.length > 0 ?
              <MbjTable titles={MatchEmployeeTitles} themeColor={"primary"} noBorderRadius>
                {matchingsMembersEmployee?.data.map((item: any) => <MatchingEmployee item={item} />)}
              </MbjTable> : <p>Aucun résultat</p>}
          </MbjCardClassic>
        </MbjWidgetClassic>
      </MbjFlex>
    </div>
  );
}
export const TopRecrutementEmployee = styled(TopRecrutementEmployeeCtrl)((props) => ({
  padding: "1% 2%",
  "to": {
    display: "block"
  },
  ".Infos": {
    padding: "0.5em 1em",
  },
  ".InformationsCardContent": {
    padding: "4px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  ".metrixContent": {
    fontSize: "1.4rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
