
import {
  MbjButton,
  MbjLoading,
  MbjModal,
  MbjModalCtrlProps,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import { useAddService, useGetMemberServices } from "@mbj-front-monorepo/data-access";
import { useForm } from "react-hook-form";
import styled from "styled-components";

interface ModalAddServiceCtrlProps extends MbjModalCtrlProps {}

const ModalAddServiceCtrl = (props: ModalAddServiceCtrlProps) => {
  const userServicesQuery = useGetMemberServices();
  const mutation = useAddService();

  const HandleSend = (data: any) => {
    mutation.mutate(
      { libelle: data.libelle },
      {
        onSettled: () => {
          props.hide();
        },
      }
    );
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  return (
    <MbjModal {...props} title="Ajout d'une équipe" maxWidth={"600px"}>
      {userServicesQuery.isLoading ? (
        <MbjLoading />
      ) : (
        <form onSubmit={handleSubmit(HandleSend)} className="serviceAddForm">
          <MbjSimpleInputContainer errors={errors} label={"Libellé du service à ajouter *"}>
            <MbjSimpleInput
              name={"libelle"}
              id={"libelle"}
              type={"text"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Libellé requis",
                },
                minLength: {
                  value: 3,
                  message: "Libellé trop court",
                },
                validate: {
                  isExist: (value) => {
                    const TabExist = userServicesQuery.data?.filter((UserService) => UserService.libelle === value);
                    console.log(!!TabExist && TabExist.length > 0);
                    return !(!!TabExist && TabExist.length > 0) || "Ce service existe déjà";
                  },
                },
              }}
            />
          </MbjSimpleInputContainer>
          <MbjButton className={"sendButton"} isPending={mutation.isLoading}>
            Ajouter
          </MbjButton>
        </form>
      )}
    </MbjModal>
  );
};

export const ModalAddService = styled(ModalAddServiceCtrl)((props) => ({
  ".serviceAddForm": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",

    button: {
      alignSelf: "end",
    },
  },
}));
