import styled from "styled-components";
import {MbjBlockMenuCpt} from "./MbjBlockMenu";
import {MbjBlockMenu} from "../../../molecules/MbjMenu/MbjMenuLinks";
import {MbjSideDirectLink} from "./MbjSideDirectLink";

interface MbjItemMenuDesktop2CtrlProps{
  className?:string;
  Block:MbjBlockMenu;
}

const MbjItemMenuDesktop2Ctrl = (props:MbjItemMenuDesktop2CtrlProps)=>{

  return (
      <div className={`wrapper_block ${props.className}`}>
        {props.Block.to ?
          <MbjSideDirectLink to={props.Block.to} libelle={props.Block.libelle} icon={props.Block.icon}/>:
          <MbjBlockMenuCpt Block={props.Block}/>
        }
      </div>
  )
}

export const MbjItemMenuDesktop2 = styled(MbjItemMenuDesktop2Ctrl)`
`
